import React, { CSSProperties, PropsWithChildren } from 'react';

export interface ModalFooterProps {
  align?: 'left' | 'right' | 'center';
  style?: CSSProperties;
}

export const ModalFooter = ({ children, align, style }: PropsWithChildren<ModalFooterProps>) => (
  <div style={{ textAlign: align || 'left', paddingTop: 25, ...style }}>{children}</div>
);
