import { DEFAULT_FALLBACK_LANGUAGE, Language, LanguageValues } from '../variables';

export const parseLanguage = (language: string): Language => {
  const exact = LanguageValues.find((l) => l === language);
  if (exact) {
    return exact;
  }
  return (
    LanguageValues.find((l) => l.substring(0, 2).toLowerCase() === language.toLowerCase()) || DEFAULT_FALLBACK_LANGUAGE
  );
};
