import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { getNsTrans } from '../../../translation';

const getClasses = makeStyles(({ palette }: Theme) => ({
  container: { textAlign: 'center' },
  header: {
    fontSize: 40,
    color: palette.primary.main,
    marginBottom: 15,
  },
  slogan: {
    fontSize: 40,
    color: palette.primary.main,
    marginBottom: 15,
  },
  button: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}));
const Trans = getNsTrans('info-haulers');
export const Haulers = () => {
  const classes = getClasses();
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Trans>MilkScout for haulers</Trans>
      </div>
      <div className={classes.slogan}>
        <Trans>We bring dairies and haulers together.</Trans>
      </div>
      <Button variant="contained" color="primary" className={classes.button}>
        <Trans>For haulers</Trans>
      </Button>
    </div>
  );
};
