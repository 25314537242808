import React, {PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Language, parseLanguage} from '../translation';
import {de, enUS, pl} from 'date-fns/locale';

const LANGUAGE_LOCALE_MAP: Record<Language, Locale> = { 'de-DE': de, 'en-US': enUS, 'pl-PL': pl };

export const PickerProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { i18n } = useTranslation();
  const locale = LANGUAGE_LOCALE_MAP[parseLanguage(i18n.language)];


  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      {children}
    </MuiPickersUtilsProvider>
  );
};
