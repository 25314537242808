import React, { CSSProperties, PropsWithChildren, ReactElement, useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { useEffectOnce, useMountedState } from '@milkscout/react';

export interface VideoProps {
  videoSrc: string;
  contentEl?: ReactElement;
  imageBackground: string;
}

const getClasses = makeStyles({
  videoWrapper: {
    width: '100%',
    margin: 'auto',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    overflow: 'hidden',
  },
  video: {
    width: 'auto',
    minWidth: '100%',
    maxWidth: 'unset',
    minHeight: '100%',
    position: 'relative',
  },
});

export const Video = ({ videoSrc, contentEl = <div />, imageBackground, children }: PropsWithChildren<VideoProps>) => {
  const [videoWrapperStyle, setVideoWrapperStyle] = useMountedState<CSSProperties>({});
  const [videoStyle, setVideoStyle] = useMountedState<CSSProperties>({});

  const contentWrapRef = useRef<HTMLElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const classes = getClasses();
  const resizeEvent = useCallback(() => {
    const { current: contentHTMLEl } = contentWrapRef;
    const { current: videoHTMLEl } = videoRef;

    if (contentHTMLEl && videoHTMLEl) {
      const { height } = contentHTMLEl.getBoundingClientRect();
      const { height: videoHeight } = videoHTMLEl.getBoundingClientRect();
      setVideoStyle({ transform: `translate(0px, ${(height - videoHeight) / 2}px)` });
      setVideoWrapperStyle({ height });
    }
  }, [setVideoStyle, setVideoWrapperStyle]);

  useEffect(() => {
    window.addEventListener('resize', resizeEvent);
    return () => {
      window.removeEventListener('resize', resizeEvent);
    };
  }, [resizeEvent]);

  useEffectOnce(() => setTimeout(resizeEvent, 250));
  return (
    <div>
      <div className={classes.videoWrapper} style={videoWrapperStyle}>
        <video
          ref={videoRef}
          autoPlay
          loop
          muted
          playsInline
          className={classes.video}
          style={videoStyle}
          poster={imageBackground}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
      </div>
      {React.cloneElement(contentEl, {
        children,
        ref: contentWrapRef,
      })}
    </div>
  );
};
