import React, { ReactNode } from 'react';
import { ABOUT_US_ROUTE, FAQ_ROUTE, HAULER_ROUTE, HOW_DOES_IT_WORK_ROUTE, PRESS_ROUTE } from '../../../../routes';
import { getNsTrans } from '../../../../../translation';

const Trans = getNsTrans('menu');

export const NAVIGATION_ITEMS: Array<{ label: ReactNode; route: string }> = [
  { label: <Trans>About us</Trans>, route: ABOUT_US_ROUTE },
  { label: <Trans>Haulers</Trans>, route: HAULER_ROUTE },
  { label: <Trans>Press</Trans>, route: PRESS_ROUTE },
  { label: <Trans>How does it work?</Trans>, route: HOW_DOES_IT_WORK_ROUTE },
  { label: <Trans>FAQ</Trans>, route: FAQ_ROUTE },
];
