import React, { CSSProperties } from 'react';

export interface PhoneProps {
  style?: CSSProperties;
  className?: string;
}

export const Phone = ({ style, className }: PhoneProps) => (
  <svg version="1.1" style={style} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 545.5">
    <path d="M138.5 80c8.375 0 16.656 3.024 23.5 8.5l1 .5.5.5 64 66 1.5 1.5-.5.5c14.174 13.212 13.968 35.53.5 49l-32 32c4.92 11.277 18.33 40.02 44.5 65 26.358 25.16 53.932 39.467 65.5 45l31.5-31.5.5-.5c13.28-13.28 36.72-13.28 50 0l.5 1 65 65c13.28 13.28 13.28 36.22 0 49.5L405 481.5l-.5.5-.5.5c-15.01 12.867-36.04 17.486-55.5 11h-.5c-37.55-14.693-113.51-48.01-181.5-116C98.773 309.773 64.052 234.84 50.5 196c-.035-.1.034-.4 0-.5-6.703-18.434-1.915-39.738 13.5-54l-.5-.5 50-51.5.5-.5 1-.5c6.844-5.476 15.125-8.5 23.5-8.5zm0 32c-1.172 0-2.344.575-3.5 1.5L86.5 163l-.5.5-.5.5c-5.674 4.863-7.44 14.493-5 21 12.12 34.847 45.2 106.7 108.5 170 62.784 62.784 133.224 93.76 169.5 108 9.34 3.113 17.11 1.833 24.5-4.5l49-49c2.72-2.72 2.72-1.78 0-4.5l-65.5-65.5c-2.72-2.72-2.28-2.72-5 0l-40 40-7.5 7.5-10-4.5s-43.83-18.552-81-52.5l-3.5-3c-38.595-36.84-57-86-57-86l-3.5-10 7.5-7 40-40c1.966-1.966.874-3.6 1-3.5l-.5-.5-1-1-64-65.5c-1.156-.925-2.328-1.5-3.5-1.5z" />
  </svg>
);
