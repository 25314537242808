import React, { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import inViewport from 'in-viewport';

export interface InViewProps {
  defaultNode: ReactNode;
  repeat?: boolean;
}

export const InView = ({ defaultNode, repeat = false, children }: PropsWithChildren<InViewProps>) => {
  const [state, setState] = useState<{ visible: boolean; done: boolean }>({ visible: false, done: false });
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const calculate = () => {
      const { current } = ref;
      if (current && !state.done) {
        const visible = inViewport(current);
        setState({ visible, done: !repeat && visible });
      }
    };
    window.addEventListener('scroll', calculate);
    window.addEventListener('resize', calculate);
    calculate();

    return () => {
      window.removeEventListener('scroll', calculate);
      window.removeEventListener('resize', calculate);
    };
  }, [state.done, repeat]);
  return (
    <div ref={ref}>
      {!state.visible && <>{defaultNode}</>}
      {state.visible && <>{children}</>}
    </div>
  );
};
