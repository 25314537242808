import React, { PropsWithChildren, useEffect } from 'react';
import { I18nextProvider as Provider, useTranslation } from 'react-i18next';
import { Language, LANGUAGE_CHANGED_EVENT_NAME } from '../variables';
import de from '../../assets/i18n/de.json';
import en from '../../assets/i18n/en.json';
import { initI18n } from '../i18n';
import { parseLanguage } from '../functions';

const Children = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const language = parseLanguage(i18n.language);
    window.dispatchEvent(new CustomEvent(LANGUAGE_CHANGED_EVENT_NAME, { detail: language }));
  }, [i18n.language]);

  return <></>;
};

const LANGUAGE_RESOURCES: Record<
  Language,
  {
    [namespace: string]: {
      [key: string]: string; // translation value
    };
  }
> = { 'de-DE': de, 'en-US': en, 'pl-PL': en };

const i18nInstance = initI18n(LANGUAGE_RESOURCES);

export const I18nextProvider = ({ children }: PropsWithChildren<unknown>) => (
  <Provider i18n={i18nInstance}>
    <Children />
    {children}
  </Provider>
);
