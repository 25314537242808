import React from 'react';
import { ExternalLink } from '../../components/ExternalLink';

export const ContentEnglish = () => (
  <>
    <h1>Privacy Policy</h1>
    <h2>1. An overview of data protection</h2>
    <h3>General information</h3>{' '}
    <p>
      The following information will provide you with an easy to navigate overview of what will happen with your
      personal data when you visit this website. The term “personal data” comprises all data that can be used to
      personally identify you. For detailed information about the subject matter of data protection, please consult our
      Data Protection Declaration, which we have included beneath this copy.
    </p>
    <h3>Data recording on this website</h3>{' '}
    <h4>Who is the responsible party for the recording of data on this website (i.e., the “controller”)?</h4>{' '}
    <p>
      The data on this website is processed by the operator of the website, whose contact information is available under
      section “Information about the responsible party (referred to as the “controller” in the GDPR)” in this Privacy
      Policy.
    </p>{' '}
    <h4>How do we record your data?</h4>{' '}
    <p>
      We collect your data as a result of your sharing of your data with us. This may, for instance be information you
      enter into our contact form.
    </p>{' '}
    <p>
      Other data shall be recorded by our IT systems automatically or after you consent to its recording during your
      website visit. This data comprises primarily technical information (e.g., web browser, operating system, or time
      the site was accessed). This information is recorded automatically when you access this website.
    </p>{' '}
    <h4>What are the purposes we use your data for?</h4>{' '}
    <p>
      A portion of the information is generated to guarantee the error free provision of the website. Other data may be
      used to analyze your user patterns.
    </p>{' '}
    <h4>What rights do you have as far as your information is concerned?</h4>{' '}
    <p>
      You have the right to receive information about the source, recipients, and purposes of your archived personal
      data at any time without having to pay a fee for such disclosures. You also have the right to demand that your
      data are rectified or eradicated. If you have consented to data processing, you have the option to revoke this
      consent at any time, which shall affect all future data processing. Moreover, you have the right to demand that
      the processing of your data be restricted under certain circumstances. Furthermore, you have the right to log a
      complaint with the competent supervising agency.
    </p>{' '}
    <p>
      Please do not hesitate to contact us at any time if you have questions about this or any other data protection
      related issues.
    </p>
    <h3>Analysis tools and tools provided by third parties</h3>{' '}
    <p>
      There is a possibility that your browsing patterns will be statistically analyzed when your visit this website.
      Such analyses are performed primarily with what we refer to as analysis programs.
    </p>{' '}
    <p>For detailed information about these analysis programs please consult our Data Protection Declaration below.</p>
    <h2>2. Hosting and Content Delivery Networks (CDN)</h2>
    <h3>External Hosting</h3>{' '}
    <p>
      This website is hosted by an external service provider (host). Personal data collected on this website are stored
      on the servers of the host. These may include, but are not limited to, IP addresses, contact requests, metadata
      and communications, contract information, contact information, names, web page access, and other data generated
      through a web site.
    </p>{' '}
    <p>
      The host is used for the purpose of fulfilling the contract with our potential and existing customers (Art.
      6(1)(b) GDPR) and in the interest of secure, fast, and efficient provision of our online services by a
      professional provider (Art. 6(1)(f) GDPR).
    </p>{' '}
    <p>
      Our host will only process your data to the extent necessary to fulfil its performance obligations and to follow
      our instructions with respect to such data.
    </p>{' '}
    <p>We are using the following host:</p>
    <p>
      netcup GmbH
      <br />
      Daimlerstraße 25
      <br />
      D-76185 Karlsruhe
      <br />
      <br />
      Amazon Web Services, Inc.
      <br />
      410 Terry Avenue North
      <br />
      Seattle WA 98109
    </p>
    <h3>Google Cloud CDN</h3>{' '}
    <p>
      We use the Google Cloud CDN content delivery network. The provider is Google Ireland Limited (“Google”), Gordon
      House, Barrow Street, Dublin 4, Ireland.
    </p>{' '}
    <p>
      Google offers a globally distributed content delivery network. Technically, the transfer of information between
      your browser and our website is routed through the Google network. This enables us to increase the global
      accessibility and performance of our website.
    </p>{' '}
    <p>
      The use of Google Cloud CDN is based on our legitimate interest in the most error-free and secure provision of our
      website (Art. 6(1)(f) GDPR).
    </p>{' '}
    <p>
      Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details
      can be found here:{' '}
      <ExternalLink ellipsis href="https://cloud.google.com/terms/eu-model-contract-clause">
        https://cloud.google.com/terms/eu-model-contract-clause
      </ExternalLink>
      .
    </p>{' '}
    <p>
      You can find more information about Google Cloud CDN here:{' '}
      <ExternalLink ellipsis href="https://cloud.google.com/cdn/docs/overview?hl=en">
        https://cloud.google.com/cdn/docs/overview?hl=en
      </ExternalLink>
      .
    </p>
    <h2>3. General information and mandatory information</h2>
    <h3>Data protection</h3>{' '}
    <p>
      The operators of this website and its pages take the protection of your personal data very seriously. Hence, we
      handle your personal data as confidential information and in compliance with the statutory data protection
      regulations and this Data Protection Declaration.
    </p>{' '}
    <p>
      Whenever you use this website, a variety of personal information will be collected. Personal data comprises data
      that can be used to personally identify you. This Data Protection Declaration explains which data we collect as
      well as the purposes we use this data for. It also explains how, and for which purpose the information is
      collected.
    </p>{' '}
    <p>
      We herewith advise you that the transmission of data via the Internet (i.e., through e-mail communications) may be
      prone to security gaps. It is not possible to completely protect data against third-party access.
    </p>
    <h3>Information about the responsible party (referred to as the “controller” in the GDPR)</h3>{' '}
    <p>The data processing controller on this website is:</p>{' '}
    <p>
      MilkScout GmbH
      <br />
      Kiem-Pauli-Straße 8<br />D – 84036 Landshut
    </p>
    <p>
      Phone: +49 871 97519000
      <br />
      E-mail: info@milkscout.eu
    </p>
    <p>
      The controller is the natural person or legal entity that single-handedly or jointly with others makes decisions
      as to the purposes of and resources for the processing of personal data (e.g., names, e-mail addresses, etc.).
    </p>
    <h3>Storage duration</h3>{' '}
    <p>
      Unless a more specific storage period has been specified in this privacy policy, your personal data will remain
      with us until the purpose for which it was collected no longer applies. If you assert a justified request for
      deletion or revoke your consent to data processing, your data will be deleted, unless we have other legally
      permissible reasons for storing your personal data (e.g., tax or commercial law retention periods); in the latter
      case, the deletion will take place after these reasons cease to apply.
    </p>
    <h3>Designation of a data protection officer as mandated by law</h3>{' '}
    <p>We have appointed a data protection officer for our company.</p>
    <p>
      Michael Mannseicher
      <br />
      MilkScout GmbH
      <br />
      Kiem-Pauli-Straße 8<br />D – 84036 Landshut
    </p>
    <p>
      Phone: +49 871 97519000
      <br />
      E-mail: m.mannseicher@milkscout.eu
    </p>
    <h3>Information on data transfer to the USA and other non-EU countries</h3>{' '}
    <p>
      Among other things, tools of companies domiciled in the United States or other from a data protection perspective
      non-secure non-EU countries, are integrated into our website. If these tools are active, your personal data may
      potentially be transferred to these non-EU countries and may be processed there. We must point out that in these
      countries, a data protection level that is comparable to that in the EU cannot be guaranteed. For instance, U.S.
      enterprises are under a mandate to release personal data to the security agencies and you as the data subject do
      not have any litigation options to defend yourself in court. Hence, it cannot be ruled out that U.S. agencies
      (e.g., the Secret Service) may process, analyze, and permanently archive your personal data for surveillance
      purposes. We have no control over these processing activities.
    </p>
    <h3>Revocation of your consent to the processing of data</h3>{' '}
    <p>
      A wide range of data processing transactions are possible only subject to your express consent. You can also
      revoke at any time any consent you have already given us. This shall be without prejudice to the lawfulness of any
      data collection that occurred prior to your revocation.
    </p>
    <h3>
      Right to object to the collection of data in special cases; right to object to direct advertising (Art. 21 GDPR)
    </h3>{' '}
    <p>
      IN THE EVENT THAT DATA ARE PROCESSED ON THE BASIS OF ART. 6(1)(E) OR (F) GDPR, YOU HAVE THE RIGHT TO AT ANY TIME
      OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA BASED ON GROUNDS ARISING FROM YOUR UNIQUE SITUATION. THIS ALSO
      APPLIES TO ANY PROFILING BASED ON THESE PROVISIONS. TO DETERMINE THE LEGAL BASIS, ON WHICH ANY PROCESSING OF DATA
      IS BASED, PLEASE CONSULT THIS DATA PROTECTION DECLARATION. IF YOU LOG AN OBJECTION, WE WILL NO LONGER PROCESS YOUR
      AFFECTED PERSONAL DATA, UNLESS WE ARE IN A POSITION TO PRESENT COMPELLING PROTECTION WORTHY GROUNDS FOR THE
      PROCESSING OF YOUR DATA, THAT OUTWEIGH YOUR INTERESTS, RIGHTS AND FREEDOMS OR IF THE PURPOSE OF THE PROCESSING IS
      THE CLAIMING, EXERCISING OR DEFENCE OF LEGAL ENTITLEMENTS (OBJECTION PURSUANT TO ART. 21(1) GDPR).
    </p>{' '}
    <p>
      IF YOUR PERSONAL DATA IS BEING PROCESSED IN ORDER TO ENGAGE IN DIRECT ADVERTISING, YOU HAVE THE RIGHT TO OBJECT TO
      THE PROCESSING OF YOUR AFFECTED PERSONAL DATA FOR THE PURPOSES OF SUCH ADVERTISING AT ANY TIME. THIS ALSO APPLIES
      TO PROFILING TO THE EXTENT THAT IT IS AFFILIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA
      WILL SUBSEQUENTLY NO LONGER BE USED FOR DIRECT ADVERTISING PURPOSES (OBJECTION PURSUANT TO ART. 21(2) GDPR).
    </p>
    <h3>Right to log a complaint with the competent supervisory agency</h3>{' '}
    <p>
      In the event of violations of the GDPR, data subjects are entitled to log a complaint with a supervisory agency,
      in particular in the member state where they usually maintain their domicile, place of work or at the place where
      the alleged violation occurred. The right to log a complaint is in effect regardless of any other administrative
      or court proceedings available as legal recourses.
    </p>
    <h3>Right to data portability</h3>{' '}
    <p>
      You have the right to demand that we hand over any data we automatically process on the basis of your consent or
      in order to fulfil a contract be handed over to you or a third party in a commonly used, machine readable format.
      If you should demand the direct transfer of the data to another controller, this will be done only if it is
      technically feasible.
    </p>
    <h3>SSL and/or TLS encryption</h3>{' '}
    <p>
      For security reasons and to protect the transmission of confidential content, such as purchase orders or inquiries
      you submit to us as the website operator, this website uses either an SSL or a TLS encryption program. You can
      recognize an encrypted connection by checking whether the address line of the browser switches from “http://” to
      “https://” and also by the appearance of the lock icon in the browser line.
    </p>{' '}
    <p>If the SSL or TLS encryption is activated, data you transmit to us cannot be read by third parties.</p>
    <h3>Information about, rectification and eradication of data</h3>{' '}
    <p>
      Within the scope of the applicable statutory provisions, you have the right to at any time demand information
      about your archived personal data, their source and recipients as well as the purpose of the processing of your
      data. You may also have a right to have your data rectified or eradicated. If you have questions about this
      subject matter or any other questions about personal data, please do not hesitate to contact us at any time.
    </p>
    <h3>Right to demand processing restrictions</h3>{' '}
    <p>
      You have the right to demand the imposition of restrictions as far as the processing of your personal data is
      concerned. To do so, you may contact us at any time. The right to demand restriction of processing applies in the
      following cases:
    </p>{' '}
    <ul>
      {' '}
      <li>
        In the event that you should dispute the correctness of your data archived by us, we will usually need some time
        to verify this claim. During the time that this investigation is ongoing, you have the right to demand that we
        restrict the processing of your personal data.
      </li>{' '}
      <li>
        If the processing of your personal data was/is conducted in an unlawful manner, you have the option to demand
        the restriction of the processing of your data in lieu of demanding the eradication of this data.
      </li>{' '}
      <li>
        If we do not need your personal data any longer and you need it to exercise, defend or claim legal entitlements,
        you have the right to demand the restriction of the processing of your personal data instead of its eradication.
      </li>{' '}
      <li>
        If you have raised an objection pursuant to Art. 21(1) GDPR, your rights and our rights will have to be weighed
        against each other. As long as it has not been determined whose interests prevail, you have the right to demand
        a restriction of the processing of your personal data.
      </li>{' '}
    </ul>{' '}
    <p>
      If you have restricted the processing of your personal data, these data – with the exception of their archiving –
      may be processed only subject to your consent or to claim, exercise or defend legal entitlements or to protect the
      rights of other natural persons or legal entities or for important public interest reasons cited by the European
      Union or a member state of the EU.
    </p>
    <h3>Rejection of unsolicited e-mails</h3>{' '}
    <p>
      We herewith object to the use of contact information published in conjunction with the mandatory information to be
      provided in our Site Notice to send us promotional and information material that we have not expressly requested.
      The operators of this website and its pages reserve the express right to take legal action in the event of the
      unsolicited sending of promotional information, for instance via SPAM messages.
    </p>
    <h2>4. Recording of data on this website</h2>
    <h3>Cookies</h3>{' '}
    <p>
      Our websites and pages use what the industry refers to as “cookies.” Cookies are small text files that do not
      cause any damage to your device. They are either stored temporarily for the duration of a session (session
      cookies) or they are permanently archived on your device (permanent cookies). Session cookies are automatically
      deleted once you terminate your visit. Permanent cookies remain archived on your device until you actively delete
      them, or they are automatically eradicated by your web browser.
    </p>{' '}
    <p>
      In some cases, it is possible that third-party cookies are stored on your device once you enter our site
      (third-party cookies). These cookies enable you or us to take advantage of certain services offered by the third
      party (e.g., cookies for the processing of payment services).
    </p>{' '}
    <p>
      Cookies have a variety of functions. Many cookies are technically essential since certain website functions would
      not work in the absence of the cookies (e.g., the shopping cart function or the display of videos). The purpose of
      other cookies may be the analysis of user patterns or the display of promotional messages.
    </p>{' '}
    <p>
      Cookies, which are required for the performance of electronic communication transactions (required cookies) or for
      the provision of certain functions you want to use (functional cookies, e.g., for the shopping cart function) or
      those that are necessary for the optimization of the website (e.g., cookies that provide measurable insights into
      the web audience), shall be stored on the basis of Art. 6(1)(f) GDPR, unless a different legal basis is cited. The
      operator of the website has a legitimate interest in the storage of cookies to ensure the technically error free
      and optimized provision of the operator’s services. If your consent to the storage of the cookies has been
      requested, the respective cookies are stored exclusively on the basis of the consent obtained (Art. 6(1)(a) GDPR);
      this consent may be revoked at any time.
    </p>{' '}
    <p>
      You have the option to set up your browser in such a manner that you will be notified any time cookies are placed
      and to permit the acceptance of cookies only in specific cases. You may also exclude the acceptance of cookies in
      certain cases or in general or activate the delete function for the automatic eradication of cookies when the
      browser closes. If cookies are deactivated, the functions of this website may be limited.
    </p>{' '}
    <p>
      In the event that third-party cookies are used or if cookies are used for analytical purposes, we will separately
      notify you in conjunction with this Data Protection Policy and, if applicable, ask for your consent.
    </p>
    <h3>Cookie Consent with Borlabs Cookie</h3>{' '}
    <p>
      Our website uses the Borlabs cookie consent technology to obtain your consent to the storage of certain cookies in
      your browser and for their data privacy protection compliant documentation. The provider of this technology is
      Borlabs - Benjamin A. Bornschein, Rübenkamp 32, 22305 Hamburg, Germany (hereinafter referred to as Borlabs).
    </p>{' '}
    <p>
      Whenever you visit our website, a Borlabs cookie will be stored in your browser, which archives any declarations
      or revocations of consent you have entered. These data are not shared with the provider of the Borlabs technology.
    </p>{' '}
    <p>
      The recorded data shall remain archived until you ask us to eradicate them, delete the Borlabs cookie on your own
      or the purpose of storing the data no longer exists. This shall be without prejudice to any retention obligations
      mandated by law. To review the details of Borlabs’ data processing policies, please visit{' '}
      <ExternalLink ellipsis href="https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/">
        https://de.borlabs.io/kb/welche-daten-speichert-borlabs-cookie/
      </ExternalLink>
    </p>{' '}
    <p>
      We use the Borlabs cookie consent technology to obtain the declarations of consent mandated by law for the use of
      cookies. The legal basis for the use of such cookies is Art. 6(1)(c) GDPR.
    </p>
    <h3>Server log files</h3>{' '}
    <p>
      The provider of this website and its pages automatically collects and stores information in so-called server log
      files, which your browser communicates to us automatically. The information comprises:
    </p>{' '}
    <ul>
      {' '}
      <li>The type and version of browser used</li> <li>The used operating system</li> <li>Referrer URL</li>{' '}
      <li>The hostname of the accessing computer</li> <li>The time of the server inquiry</li> <li>The IP address</li>{' '}
    </ul>{' '}
    <p>This data is not merged with other data sources.</p>{' '}
    <p>
      This data is recorded on the basis of Art. 6(1)(f) GDPR. The operator of the website has a legitimate interest in
      the technically error free depiction and the optimization of the operator’s website. In order to achieve this,
      server log files must be recorded.
    </p>
    <h3>Contact form</h3>{' '}
    <p>
      If you submit inquiries to us via our contact form, the information provided in the contact form as well as any
      contact information provided therein will be stored by us in order to handle your inquiry and in the event that we
      have further questions. We will not share this information without your consent.
    </p>{' '}
    <p>
      The processing of these data is based on Art. 6(1)(b) GDPR, if your request is related to the execution of a
      contract or if it is necessary to carry out pre-contractual measures. In all other cases the processing is based
      on our legitimate interest in the effective processing of the requests addressed to us (Art. 6(1)(f) GDPR) or on
      your agreement (Art. 6(1)(a) GDPR) if this has been requested.
    </p>{' '}
    <p>
      The information you have entered into the contact form shall remain with us until you ask us to eradicate the
      data, revoke your consent to the archiving of data or if the purpose for which the information is being archived
      no longer exists (e.g., after we have concluded our response to your inquiry). This shall be without prejudice to
      any mandatory legal provisions, in particular retention periods.
    </p>
    <h3>Request by e-mail, telephone, or fax</h3>{' '}
    <p>
      If you contact us by e-mail, telephone or fax, your request, including all resulting personal data (name, request)
      will be stored and processed by us for the purpose of processing your request. We do not pass these data on
      without your consent.
    </p>{' '}
    <p>
      These data are processed on the basis of Art. 6(1)(b) GDPR if your inquiry is related to the fulfillment of a
      contract or is required for the performance of pre-contractual measures. In all other cases, the data are
      processed on the basis of our legitimate interest in the effective handling of inquiries submitted to us (Art.
      6(1)(f) GDPR) or on the basis of your consent (Art. 6(1)(a) GDPR) if it has been obtained.
    </p>{' '}
    <p>
      The data sent by you to us via contact requests remain with us until you request us to delete, revoke your consent
      to the storage or the purpose for the data storage lapses (e.g. after completion of your request). Mandatory
      statutory provisions - in particular statutory retention periods - remain unaffected.
    </p>
    <h3>Use of Chatbots</h3>{' '}
    <p>
      We use chatbots to communicate with you. Chatbots have the capability to respond to your questions and other
      entries without the assistance of humans. To do this, chatbots analyze your entries and other data to give
      matching responses (e.g., names, email addresses and other contact information, customer numbers and other
      identification, orders, and chat progresses). The chatbot can also register your IP address, log files, location
      information and other meta data. The data is archived on the servers of the chatbot provider.
    </p>{' '}
    <p>
      It is possible to generate user profiles based on the recorded data. Moreover, the data can be used to display
      interest-related advertising if the other legal requirements are met (in particular if consent has been obtained).
      Moreover, it is possible to link chatbots to analytical and advertising tools.
    </p>{' '}
    <p>The recorded data can also be used to improve our chatbots and their response patterns (machine learning).</p>{' '}
    <p>
      We or the chatbot operator retain the data you enter until you ask us to delete it, revoke your consent to archive
      it or if the purpose for the data storage is no longer in effect (e.g., once your inquiry has been fully
      processed). This does not affect mandatory statutory provisions – in particular, retention time frames.
    </p>{' '}
    <p>
      The legal basis for the use of chatbots is Art. 6(1)(b) GDPR, if the chatbot is used to negotiate a contract or in
      conjunction with the fulfillment of a contract. If pertinent consent has been requested, the processing shall
      occur exclusively on the basis of Art. 6(1)(a) GDPR; the consent may be revoked at any time. In all other cases,
      the use is based on our legitimate interest in the most effective client communication possible (Art. 6(1)(f)
      GDPR).
    </p>
    <h3>Registration on this website</h3>{' '}
    <p>
      You have the option to register on this website to be able to use additional website functions. We shall use the
      data you enter only for the purpose of using the respective offer or service you have registered for. The required
      information we request at the time of registration must be entered in full. Otherwise, we shall reject the
      registration.
    </p>{' '}
    <p>
      To notify you of any important changes to the scope of our portfolio or in the event of technical modifications,
      we shall use the e-mail address provided during the registration process.
    </p>{' '}
    <p>
      We shall process the data entered during the registration process on the basis of your consent (Art. 6(1)(a)
      GDPR).
    </p>{' '}
    <p>
      The data recorded during the registration process shall be stored by us as long as you are registered on this
      website. Subsequently, such data shall be deleted. This shall be without prejudice to mandatory statutory
      retention obligations.
    </p>
    <h2>5. Social media</h2>
    <h3>LinkedIn plug-in</h3>{' '}
    <p>
      This website uses functions of the LinkedIn network. The provider is LinkedIn Ireland Unlimited Company, Wilton
      Plaza, Wilton Place, Dublin 2, Ireland.
    </p>{' '}
    <p>
      Any time you access a page of this website that contains functions of LinkedIn, a connection to LinkedIn’s servers
      is established. LinkedIn is notified that you have visited this website with your IP address. If you click on
      LinkedIn’s “Recommend” button and are logged into your LinkedIn account at the time, LinkedIn will be in a
      position to allocate your visit to this website to your user account. We have to point out that we as the provider
      of the websites do not have any knowledge of the content of the transferred data and its use by LinkedIn.
    </p>{' '}
    <p>
      The use of the LinkedIn plug-in is based on Art. 6(1)(f) GDPR. The operator of the website has a legitimate
      interest in being as visible as possible on social media. If a respective declaration of consent has been
      obtained, the data shall be processed exclusively on the basis of Art. 6(1)(a) GDPR. This declaration of consent
      may be revoked at any time.
    </p>{' '}
    <p>
      Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details
      can be found here:{' '}
      <ExternalLink
        ellipsis
        href="https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=en"
      >
        https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=en
      </ExternalLink>
      .
    </p>{' '}
    <p>
      For further information on this subject, please consult LinkedIn’s Data Privacy Declaration at:{' '}
      <ExternalLink ellipsis href="https://www.linkedin.com/legal/privacy-policy">
        https://www.linkedin.com/legal/privacy-policy
      </ExternalLink>
      .
    </p>
    <h2>6. Analysis tools and advertising</h2>
    <h3>Google Tag Manager</h3>{' '}
    <p>
      We use the Google Tag Manager. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
      Ireland
    </p>{' '}
    <p>
      The Google Tag Manager is a tool that allows us to integrate tracking or statistical tools and other technologies
      on our website. The Google Tag Manager itself does not create any user profiles, does not store cookies, and does
      not carry out any independent analyses. It only manages and runs the tools integrated via it. However, the Google
      Tag Manager does collect your IP address, which may also be transferred to Google’s parent company in the United
      States.
    </p>{' '}
    <p>
      The Google Tag Manager is used on the basis of Art. 6(1)(f) GDPR. The website operator has a legitimate interest
      in the quick and uncomplicated integration and administration of various tools on his website. If the relevant
      consent has been requested, the processing is carried out exclusively on the basis of Art. 6(1)(a) GDPR; the
      consent can be revoked at any time.
    </p>
    <h3>Google Analytics</h3>{' '}
    <p>
      This website uses functions of the web analysis service Google Analytics. The provider of this service is Google
      Ireland Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
    </p>{' '}
    <p>
      Google Analytics enables the website operator to analyze the behavior patterns of website visitors. To that end,
      the website operator receives a variety of user data, such as pages accessed, time spent on the page, the utilized
      operating system and the user’s origin. Google may consolidate these data in a profile that is allocated to the
      respective user or the user’s device.
    </p>{' '}
    <p>
      Google Analytics uses technologies that make the recognition of the user for the purpose of analyzing the user
      behavior patterns (e.g., cookies or device fingerprinting). The website use information recorded by Google is, as
      a rule transferred to a Google server in the United States, where it is stored.
    </p>{' '}
    <p>
      This analysis tool is used on the basis of Art. 6(1)(f) GDPR. The operator of this website has a legitimate
      interest in the analysis of user patterns to optimize both, the services offered online and the operator’s
      advertising activities. If a corresponding agreement has been requested (e.g., an agreement to the storage of
      cookies), the processing takes place exclusively on the basis of Art. 6(1)(a) GDPR; the agreement can be revoked
      at any time.
    </p>{' '}
    <p>
      Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details
      can be found here:{' '}
      <ExternalLink ellipsis href="https://privacy.google.com/businesses/controllerterms/mccs/">
        https://privacy.google.com/businesses/controllerterms/mccs/
      </ExternalLink>
      .
    </p>{' '}
    <h4>IP anonymization</h4>{' '}
    <p>
      On this website, we have activated the IP anonymization function. As a result, your IP address will be abbreviated
      by Google within the member states of the European Union or in other states that have ratified the Convention on
      the European Economic Area prior to its transmission to the United States. The full IP address will be transmitted
      to one of Google’s servers in the United States and abbreviated there only in exceptional cases. On behalf of the
      operator of this website, Google shall use this information to analyze your use of this website to generate
      reports on website activities and to render other services to the operator of this website that are related to the
      use of the website and the Internet. The IP address transmitted in conjunction with Google Analytics from your
      browser shall not be merged with other data in Google’s possession.
    </p>
    <h4>Browser plug-in</h4>{' '}
    <p>
      You can prevent the recording and processing of your data by Google by downloading and installing the browser
      plugin available under the following link:{' '}
      <ExternalLink ellipsis href="https://tools.google.com/dlpage/gaoptout?hl=en">
        https://tools.google.com/dlpage/gaoptout?hl=en
      </ExternalLink>
      .
    </p>{' '}
    <p>
      For more information about the handling of user data by Google Analytics, please consult Google’s Data Privacy
      Declaration at:{' '}
      <ExternalLink ellipsis href="https://support.google.com/analytics/answer/6004245?hl=en">
        https://support.google.com/analytics/answer/6004245?hl=en
      </ExternalLink>
      .
    </p>
    <h4>Demographic parameters provided by Google Analytics</h4>{' '}
    <p>
      This website uses the “demographic characteristics” function of Google Analytics, to be able to display to the
      website visitor compatible ads within the Google advertising network. This allows reports to be created that
      contain information about the age, gender, and interests of the website visitors. The sources of this information
      are interest-related advertising by Google as well as visitor data obtained from third-party providers. This data
      cannot be allocated to a specific individual. You have the option to deactivate this function at any time by
      making pertinent settings changes for advertising in your Google account or you can generally prohibit the
      recording of your data by Google Analytics as explained in section “Objection to the recording of data”.
    </p>
    <h4>Archiving period</h4>{' '}
    <p>
      Data on the user or incident level stored by Google linked to cookies, user IDs or advertising IDs (e.g.,
      DoubleClick cookies, Android advertising ID) will be anonymized or deleted after 14 month. For details, please
      click the following link:{' '}
      <ExternalLink ellipsis href="https://support.google.com/analytics/answer/7667196?hl=en">
        https://support.google.com/analytics/answer/7667196?hl=en
      </ExternalLink>
    </p>
    <h2>7. Plug-ins and Tools</h2>
    <h3>Google Web Fonts</h3>{' '}
    <p>
      To ensure that fonts used on this website are uniform, this website uses so-called Web Fonts provided by Google.
      When you access a page on our website, your browser will load the required web fonts into your browser cache to
      correctly display text and fonts.
    </p>{' '}
    <p>
      To do this, the browser you use will have to establish a connection with Google’s servers. As a result, Google
      will learn that your IP address was used to access this website. The use of Google Web Fonts is based on Art.
      6(1)(f) GDPR. The website operator has a legitimate interest in a uniform presentation of the font on the
      operator’s website. If a respective declaration of consent has been obtained (e.g., consent to the archiving of
      cookies), the data will be processed exclusively on the basis of Art. 6(1)(a) GDPR. Any such consent may be
      revoked at any time.
    </p>{' '}
    <p>If your browser should not support Web Fonts, a standard font installed on your computer will be used.</p>{' '}
    <p>
      For more information on Google Web Fonts, please follow this link:{' '}
      <ExternalLink ellipsis href="https://developers.google.com/fonts/faq">
        https://developers.google.com/fonts/faq
      </ExternalLink>{' '}
      and consult Google’s Data Privacy Declaration under:{' '}
      <ExternalLink ellipsis href="https://policies.google.com/privacy?hl=en">
        https://policies.google.com/privacy?hl=en
      </ExternalLink>
      .
    </p>
    <h3>Google Maps</h3>{' '}
    <p>
      This website uses the mapping service Google Maps. The provider is Google Ireland Limited (“Google”), Gordon
      House, Barrow Street, Dublin 4, Ireland.
    </p>{' '}
    <p>
      To enable the use of the Google Maps features, your IP address must be stored. As a rule, this information is
      transferred to one of Google’s servers in the United States, where it is archived. The operator of this website
      has no control over the data transfer. In case Google Maps has been activated, Google has the option to use Google
      web fonts for the purpose of the uniform depiction of fonts. When you access Google Maps, your browser will load
      the required web fonts into your browser cache, to correctly display text and fonts.
    </p>{' '}
    <p>
      We use Google Maps to present our online content in an appealing manner and to make the locations disclosed on our
      website easy to find. This constitutes a legitimate interest as defined in Art. 6(1)(f) GDPR. If a respective
      declaration of consent has been obtained, the data shall be processed exclusively on the basis of Art. 6(1)(a)
      GDPR. This declaration of consent may be revoked at any time.
    </p>{' '}
    <p>
      Data transmission to the US is based on the Standard Contractual Clauses (SCC) of the European Commission. Details
      can be found here:{' '}
      <ExternalLink ellipsis href="https://privacy.google.com/businesses/gdprcontrollerterms/">
        https://privacy.google.com/businesses/gdprcontrollerterms/
      </ExternalLink>{' '}
      and{' '}
      <ExternalLink ellipsis href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/">
        https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
      </ExternalLink>
      .
    </p>{' '}
    <p>
      For more information on the handling of user data, please review Google’s Data Privacy Declaration under:{' '}
      <ExternalLink ellipsis href="https://policies.google.com/privacy?hl=en">
        https://policies.google.com/privacy?hl=en
      </ExternalLink>
      .
    </p>
    <h3>Google reCAPTCHA</h3>{' '}
    <p>
      We use “Google reCAPTCHA” (hereinafter referred to as “reCAPTCHA”) on this website. The provider is Google Ireland
      Limited (“Google”), Gordon House, Barrow Street, Dublin 4, Ireland.
    </p>{' '}
    <p>
      The purpose of reCAPTCHA is to determine whether data entered on this website (e.g., information entered into a
      contact form) is being provided by a human user or by an automated program. To determine this, reCAPTCHA analyzes
      the behavior of the website visitors based on a variety of parameters. This analysis is triggered automatically as
      soon as the website visitor enters the site. For this analysis, reCAPTCHA evaluates a variety of data (e.g., IP
      address, time the website visitor spent on the site or cursor movements initiated by the user). The data tracked
      during such analyses are forwarded to Google.
    </p>{' '}
    <p>
      reCAPTCHA analyses run entirely in the background. Website visitors are not alerted that an analysis is underway.
    </p>{' '}
    <p>
      Data are stored and analyzed on the basis of Art. 6(1)(f) GDPR. The website operator has a legitimate interest in
      the protection of the operator’s websites against abusive automated spying and against SPAM. If a respective
      declaration of consent has been obtained, the data will be processed exclusively on the basis of Art. 6(1)(a)
      GDPR. Any such consent may be revoked at any time.
    </p>{' '}
    <p>
      For more information about Google reCAPTCHA please refer to the Google Data Privacy Declaration and Terms Of Use
      under the following links:{' '}
      <ExternalLink ellipsis href="https://policies.google.com/privacy?hl=en">
        https://policies.google.com/privacy?hl=en
      </ExternalLink>{' '}
      and{' '}
      <ExternalLink ellipsis href="https://policies.google.com/terms?hl=en">
        https://policies.google.com/terms?hl=en
      </ExternalLink>
      .
    </p>
    <h3>Tidio</h3>{' '}
    <p>
      We use Tidio (hereinafter referred to as “Tidio”) for the processing of user inquiries via our support channels or
      live chat systems. The provider is Tidio LLC, 180 Steuart St, CA 94119, San Francisco, California, USA.
    </p>{' '}
    <p>
      Messages you send to us, can be stored in the Tidio ticket system or our employees respond to them in the live
      chat system. If you communicate with us via Tidio, all data you have entered from the start of the chat (i.e.,
      name or chat ID, address, and phone number) as well as your IP address, your country of origin, the utilized
      browser and device, the accessed website and the exchanges messages are consolidated in a profile and saved on
      Tidio’s servers.
    </p>{' '}
    <p>
      Messages that are addressed to us remain in our possession until you ask us to delete them or the reason for the
      data storage is no longer effective (e. g. after your inquiry has been processed). This shall be without prejudice
      to any statutory provisions – especially statutory mandatory retention obligations.
    </p>{' '}
    <p>
      We also use Tidio to analyze the patterns of our users. This allows us, for instance, to determine how many users
      have accessed our website or completed the contact form.
    </p>
    <p>
      The use of Tidio is based on Art. 6(1)(f) GDPR. We have a legitimate interest in marketing activities that are as
      effective as possible. If your pertinent consent was requested, the processing shall occur exclusively on the
      basis of Art. 6(1)(a) GDPR; this consent may be revoked at any time.
    </p>{' '}
    <p>
      For more information, please consult the data privacy declaration of Tidio:{' '}
      <ExternalLink ellipsis href="https://www.tidio.com/privacy-policy/">
        https://www.tidio.com/privacy-policy/
      </ExternalLink>
      .
    </p>
    <h2>8. eCommerce and payment service providers</h2>
    <h3>Processing of data (customer and contract data)</h3>{' '}
    <p>
      We collect, process, and use personal data only to the extent necessary for the establishment, content
      organization or change of the legal relationship (data inventory). These actions are taken on the basis of Art.
      6(1)(b) GDPR, which permits the processing of data for the fulfilment of a contract or pre-contractual actions. We
      collect, process, and use personal data concerning the use of this website (usage data) only to the extent that
      this is necessary to make it possible for users to utilize the services and to bill for them.
    </p>{' '}
    <p>
      The collected customer data shall be eradicated upon completion of the order or the termination of the business
      relationship. This shall be without prejudice to any statutory retention mandates.
    </p>
    <h3>Data transfer upon closing of contracts for services and digital content</h3>{' '}
    <p>
      We share personal data with third parties only if this is necessary in conjunction with the handling of the
      contract; for instance, with the financial institution tasked with the processing of payments.
    </p>{' '}
    <p>
      Any further transfer of data shall not occur or shall only occur if you have expressly consented to the transfer.
      Any sharing of your data with third parties in the absence of your express consent, for instance for advertising
      purposes, shall not occur.
    </p>{' '}
    <p>
      The basis for the processing of data is Art. 6(1)(b) GDPR, which permits the processing of data for the fulfilment
      of a contract or for pre-contractual actions.
    </p>
  </>
);
