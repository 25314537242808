import React, { ReactNode } from 'react';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const getClasses = makeStyles({
  root: { boxShadow: 'unset' },
  summaryRoot: {
    boxShadow: 'unset',
    padding: '0 !important',
    minHeight: 'unset !important',
  },
  summaryContent: {
    margin: '0 !important',
  },
  summaryDetails: {
    padding: '0 !important',
  },
});
export interface AccordionsProps {
  summary: ReactNode;
  details: ReactNode;
}

export const Accordion = ({ summary, details }: AccordionsProps) => {
  const classes = getClasses();
  return (
    <MuiAccordion classes={{ root: classes.root }}>
      <AccordionSummary classes={{ root: classes.summaryRoot, content: classes.summaryContent }}>
        {summary}
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.summaryDetails }}>{details}</AccordionDetails>
    </MuiAccordion>
  );
};
