import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getNsTrans } from '../../../translation';

const getClasses = makeStyles(({ palette }: Theme) => ({
  container: {
    padding: 40,
    textAlign: 'center',
  },
  milkMatch: {
    backgroundColor: palette.primary.main,
    color: '#ffffff',
  },
  milkAlarm: {
    backgroundColor: '#ffffff',
    color: palette.primary.main,
  },
  button: {
    color: '#ffffff',
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  header: {
    fontSize: 40,
  },
  content: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 20,
    marginTop: 20,
  },
}));
const Trans = getNsTrans('info-features');
export const FeatureInfo = () => {
  const classes = getClasses();
  return (
    <Grid container>
      <Grid item md={6} xs={12} className={`${classes.container} ${classes.milkMatch}`}>
        <div className={classes.header}>
          <Trans>Raw material reconciliation - Directly via the platform!</Trans>
        </div>
        <div className={classes.content}>
          <Trans>With MilkMatch you can easily and effortlessly handle your raw material reconciliations online.</Trans>
        </div>
        <Button variant="contained" color="secondary" className={classes.button}>
          <Trans>More about Milkmatch</Trans>
        </Button>
      </Grid>
      <Grid item md={6} xs={12} className={`${classes.container} ${classes.milkAlarm}`}>
        <div className={classes.header}>
          <Trans>Receive market information by mail!</Trans>
        </div>
        <div className={classes.content}>
          <Trans>
            Through our innovative MilkAlarm, you will be notified immediately if your product is available at your
            desired price.
          </Trans>
        </div>
        <Button variant="contained" color="secondary" className={classes.button}>
          <Trans>More about MilkAlarm</Trans>
        </Button>
      </Grid>
    </Grid>
  );
};
