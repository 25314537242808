import React from 'react';
import { makeStyles, Toolbar } from '@material-ui/core';
import { Hide } from '@milkscout/react';
import { useHistory } from 'react-router-dom';
import milkscoutLogo from '../../../../../assets/img/logo-big.svg';
import { MenuButtons } from './MenuButtons';
import { LanguageMenu, NavigationMenu } from '../Menu';

const getClasses = makeStyles((theme) => ({
  logo: {
    height: 54,
    alignSelf: 'center',
    cursor: 'pointer',
  },
  menu: {
    flexGrow: 1,
    alignSelf: 'center',
    display: 'flex',
  },
  toolbar: {
    minHeight: 110,
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
}));

export interface DesktopToolbarProps {
  isDesktop: boolean;
}

export const ToolbarDesktop = ({ isDesktop }: DesktopToolbarProps) => {
  const classes = getClasses();
  const history = useHistory();

  return (
    <Hide show={isDesktop}>
      <Toolbar className={classes.toolbar}>
        <img src={milkscoutLogo} className={classes.logo} alt="MilkScout logo" onClick={() => history.push('/')} />
        <div className={classes.menu}>
          <NavigationMenu />
          <LanguageMenu />
        </div>
        <MenuButtons />
      </Toolbar>
    </Hide>
  );
};
