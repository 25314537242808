import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getFaqCosts, getFaqProducts, getFaqSell, getFaqTransport } from '../../faq/FaqPage';

const getClasses = makeStyles(({ palette }: Theme) => ({
  header: {
    fontSize: 45,
    color: palette.primary.main,
    textAlign: 'center',
    marginBottom: 15,
  },
  container: {
    paddingTop: 40,
    paddingBottom: 40,
    backgroundColor: palette.grey['100'],
  },
  image: {
    maxWidth: '100%',
  },
  faq: {},
}));

export const Faq = () => {
  const classes = getClasses();
  return (
    <div className={classes.container}>
      <Container maxWidth="lg">
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <div className={classes.header}>F.A.Q.</div>
          </Grid>
          <Grid item xs={8} className={classes.faq}>
            {getFaqCosts()}
            {getFaqSell()}
            {getFaqProducts()}
            {getFaqTransport()}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
