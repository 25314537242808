import React from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import WorldMilkMapUrl from '../../../assets/img/milk-planet.jpg';
import { InfoNumbers } from './InfoNumbers';

export const ParallaxImage = () => (
  <>
    <ParallaxBanner
      layers={[
        {
          image: WorldMilkMapUrl,
          speed: -100,
          children: <InfoNumbers />,
        },
      ]}
      style={{ aspectRatio: '5 / 1.5' }}
    />
  </>
);
