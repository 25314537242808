import React from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { openUrlInNewTab } from '@milkscout/react';
import { useHistory } from 'react-router-dom';
import { FooterCard } from './FooterCard';
import { MapMarker } from '../../../../../components/images/MapMarker';
import { Email } from '../../../../../components/images/Email';
import { Phone } from '../../../../../components/images/Phone';
import { getNsTrans } from '../../../../../translation';
import { DATA_PRIVACY_ROUTE, IMPRINT_ROUTE, TERMS_OF_USE_ROUTE } from '../../../../routes';

const getClasses = makeStyles(({ palette }: Theme) => ({
  info: {
    backgroundColor: palette.primary.main,
    padding: 30,
  },
  bottom: {
    backgroundColor: '#17252a',
    color: '#fff',
    padding: 30,
  },
  link: {
    margin: 20,
    fontSize: 16,
    textAlign: 'center',
    fontWeight: 500,
    cursor: 'pointer',
    color: palette.primary.main,
    textTransform: 'uppercase',
  },
  copyRight: {
    margin: 20,
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
    color: 'rgb(153, 153, 153)',
  },
}));

const Trans = getNsTrans('footer');

export const Footer = () => {
  const history = useHistory();
  const classes = getClasses();
  return (
    <div>
      <div className={classes.info}>
        <Container maxWidth="lg">
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <FooterCard
                onClick={() => openUrlInNewTab(process.env.REACT_APP_MILKSCOUT_ADDRESS_GOOGLE_MAPS_LINK as string)}
                icon={<MapMarker />}
              >
                {process.env.REACT_APP_MILKSCOUT_ADDRESS as string}
              </FooterCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FooterCard
                onClick={() => {
                  window.location.href = `mailto:${process.env.REACT_APP_MILKSCOUT_INFO_MAIL}` as string;
                }}
                icon={<Email />}
              >
                {process.env.REACT_APP_MILKSCOUT_INFO_MAIL as string}
              </FooterCard>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FooterCard
                onClick={() => {
                  window.location.href = `tel:${process.env.REACT_APP_MILKSCOUT_PHONE}` as string;
                }}
                icon={<Phone />}
              >
                {process.env.REACT_APP_MILKSCOUT_PHONE as string}
              </FooterCard>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.bottom}>
        <Container maxWidth="lg">
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div className={classes.link} onClick={() => history.push(TERMS_OF_USE_ROUTE)}>
                <Trans>Terms of use</Trans>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <div className={classes.link} onClick={() => history.push(DATA_PRIVACY_ROUTE)}>
                <Trans>Data privacy</Trans>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <div className={classes.link} onClick={() => history.push(IMPRINT_ROUTE)}>
                <Trans>Impress</Trans>
              </div>
            </Grid>
            <Grid item xs={12} lg={5}>
              <div className={classes.copyRight}>
                <span>{`© COPYRIGHT ${new Date().getFullYear()} - MilkScout GmbH. `}</span>
                <Trans>All rights reserved.</Trans>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
