import React, { PropsWithChildren } from 'react';
import { Container, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PageLayout } from '../../page/layout';

const getClasses = makeStyles({
  container: {
    marginTop: 50,
    marginBottom: 50,
    maxWidth: 650,
  },
});

export const InfoPageLayout = ({ children }: PropsWithChildren<unknown>) => {
  const classes = getClasses();
  const { palette } = useTheme();
  return (
    <PageLayout trackingDisabled>
      <style>{`#info-content h1, #info-content h2, #info-content h3, #info-content h4, #info-content h5 { color: ${palette.primary.main}; }`}</style>
      <Container maxWidth="lg" className={classes.container}>
        <div id="info-content">{children}</div>
      </Container>
    </PageLayout>
  );
};
