import React, { PropsWithChildren } from 'react';

import { makeStyles } from '@material-ui/core/styles';

const getClasses = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
    marginTop: 15,
    textTransform: 'uppercase',
  },
  line: { flex: 1, borderTop: '1px black solid', marginTop: 9 },
  lineLeft: { marginRight: 5 },
  lineRight: { marginLeft: 5 },
});
export const Divider = ({ children }: PropsWithChildren<unknown>) => {
  const classes = getClasses();
  return (
    <div className={classes.root}>
      <div className={`${classes.line} ${classes.lineLeft}`} />
      {children}
      <div className={`${classes.line} ${classes.lineRight}`} />
    </div>
  );
};
