import React, { PropsWithChildren } from 'react';
import { bounce } from 'react-animations';
import Radium, { StyleRoot } from 'radium';

export interface FadeInLeftAnimationProps {
  duration?: number;
}

export const BounceAnimation = ({ children, duration = 1 }: PropsWithChildren<FadeInLeftAnimationProps>) => (
  <StyleRoot>
    <div
      className="test"
      style={{
        animation: `x ${duration}s`,
        animationName: Radium.keyframes(bounce, 'bounce'),
      }}
    >
      {children}
    </div>
  </StyleRoot>
);
