import React, { CSSProperties } from 'react';

export interface DesktopMonitorProps {
  style?: CSSProperties;
  className?: string;
}

export const DesktopMonitor = ({ style, className }: DesktopMonitorProps) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={style} className={className}>
    <path d="M448 341V85H64v256h384zm0-298q17 0 30 12.5T491 85v256q0 17-13 30t-30 13H299v43h42v42H171v-42h42v-43H64q-17 0-30-13t-13-30V85q0-17 13-29.5T64 43h384z" />
  </svg>
);
