import React from 'react';
import { Container } from '@material-ui/core';
import { PageLayout } from '../layout';
import { Banner } from './components/Banner';
import { ListItem } from '../main/components/ListItem';
import { getNsTrans } from '../../translation';

const Trans = getNsTrans('hauler-page');

export const HaulerPage = () => (
  <PageLayout>
    <Banner />
    <Container maxWidth="lg">
      <div>
        <Trans>New options for your hauler company</Trans>
      </div>
      <div>
        <Trans>
          MilkScout is an independent platform that simplifies milk trading and helps you realize the full potential of
          the dairy market.
        </Trans>
      </div>
      <div>
        <ListItem>
          <Trans>Receive transport requests free of charge</Trans>
        </ListItem>
        <ListItem>
          <Trans>Avoid expensive empty runs</Trans>
        </ListItem>
        <ListItem>
          <Trans>Use MilkScout as an advertising platform</Trans>
        </ListItem>
        <ListItem>
          <Trans>Free registration, no base fee</Trans>
        </ListItem>
        <ListItem>
          <Trans>Simple specification of offers</Trans>
        </ListItem>
        <ListItem>
          <Trans>Independent trading platform</Trans>
        </ListItem>
        <ListItem>
          <Trans>Find new business partners</Trans>
        </ListItem>
      </div>
      <div>
        <Trans>
          Register now so that dairies can add you to their hauler list and you can receive offers for transport orders.
        </Trans>
      </div>
    </Container>
  </PageLayout>
);
