import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AccountGroupIcon } from '@material-ui-extra/icons/AccountGroupIcon';
import { GridIcon } from '@material-ui-extra/icons/GridIcon';
import { CompanyIcon } from '@material-ui-extra/icons/CompanyIcon';
import { HandshakeIcon } from '@material-ui-extra/icons/HandshakeIcon';
import { getNsTrans } from '../../../translation';
import { MilkTruckIcon } from '../../../components/Icons/MilkTruckIcon';
import { CreameryIcon } from '../../../components/Icons/CreameryIcon';
import { Counter } from '../../../components/Counter';
import { InView } from '../../../components/InView';

const Trans = getNsTrans('info-numbers');

const getClasses = makeStyles(({ palette }: Theme) => ({
  root: {},
  numberItem: {
    textAlign: 'center',
  },
  icon: {
    fontSize: 80,
  },
  numberHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    textTransform: 'uppercase',
    fontSize: 36,
    color: palette.primary.main,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  numberText: {
    marginLeft: 10,
    color: palette.primary.main,
    fontWeight: 600,
    fontSize: 25,
  },
  text: {
    fontSize: 20,
    fontWeight: 600,
    color: palette.primary.main,
  },
}));

export const InfoNumbers = () => {
  const classes = getClasses();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={6} md={3} className={classes.numberItem}>
          <div>
            <AccountGroupIcon color="primary" className={classes.icon} />
            <div className={classes.text}>
              <Trans>Weekly visitors</Trans>
            </div>
            <div className={classes.numberText}>300+</div>
          </div>
        </Grid>
        <Grid item xs={6} md={3} className={classes.numberItem}>
          <div>
            <GridIcon color="primary" className={classes.icon} />
            <div className={classes.text}>
              <Trans>Offers</Trans>
            </div>
            <div className={classes.numberText}>100+</div>
          </div>
        </Grid>
        <Grid item xs={6} md={3} className={classes.numberItem}>
          <div>
            <AccountGroupIcon color="primary" className={classes.icon} />
            <div className={classes.text}>
              <Trans>Registered users</Trans>
            </div>
            <div className={classes.numberText}>110+</div>
          </div>
        </Grid>
        <Grid item xs={6} md={3} className={classes.numberItem}>
          <div>
            <CompanyIcon color="primary" className={classes.icon} />
            <div className={classes.text}>
              <Trans>Foundation</Trans>
            </div>
            <div className={classes.numberText}>2020</div>
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.root} justifyContent="center">
        <Grid item xs={12} sm={6} className={classes.numberItem}>
          <div>
            <CreameryIcon height={80} />
            <div className={classes.text}>
              <Trans>Creameries</Trans>
            </div>
            <InView defaultNode={<div className={classes.numberText}>0+</div>}>
              <div className={classes.numberText}>
                <Counter end={60} durationInSeconds={2.2} />+
              </div>
            </InView>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.numberItem}>
          <div>
            <MilkTruckIcon height={80} />
            <div className={classes.text}>
              <Trans>Haulers</Trans>
            </div>
            <div className={classes.numberText}>
              <InView defaultNode={<div className={classes.numberText}>0+</div>}>
                <Counter end={45} durationInSeconds={2.2} />+
              </InView>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.numberItem}>
          <div>
            <HandshakeIcon color="primary" className={classes.icon} />
            <div className={classes.text}>
              <Trans>Broker</Trans>
            </div>
            <div className={classes.numberText}>
              <InView defaultNode={<div className={classes.numberText}>0+</div>}>
                <Counter end={9} durationInSeconds={2.2} />+
              </InView>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
