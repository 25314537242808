import React from 'react';
import { Button, Container, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getNsTrans } from '../../../translation';
import imageSrc from '../../../assets/img/our-mission.png';
import { ListItemIcon } from '../../../components/images/ListItemIcon';

const getClasses = makeStyles(({ palette }: Theme) => ({
  root: {
    backgroundColor: palette.primary.main,
    padding: 40,
    fontWeight: 700,
    color: '#ffffff',
    paddingBottom: 80,
    paddingTop: 80,
  },
  header: { fontSize: 20, textTransform: 'uppercase' },
  slogan: {
    marginTop: 20,
    fontSize: 35,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  contentContainer: { justifyContent: 'center', display: 'flex', flexDirection: 'column' },
  content: {
    fontSize: 16,
    marginBottom: 20,
    marginTop: 20,
  },
  imageContainer: {
    paddingLeft: 40,
    paddingRight: 40,
    textAlign: 'center',
  },
  button: {
    color: '#fff',
    fill: '#fff',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    '&:hover': {
      color: palette.primary.main,
      fill: palette.primary.main,
      backgroundColor: '#fff',
    },
  },
}));
const Trans = getNsTrans('info-about-us');
export const InfoAboutUs = () => {
  const classes = getClasses();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} md={6} className={classes.imageContainer}>
            <img src={imageSrc} style={{ width: '100%', maxWidth: 450 }} alt="working as team" />
          </Grid>
          <Grid item xs={12} md={6} className={classes.contentContainer}>
            <div className={classes.header}>
              <Trans>We about us</Trans>
            </div>
            <div className={classes.slogan}>
              <Trans>Your milk. Our mission.</Trans>
            </div>
            <div className={classes.content}>
              <Trans>
                We simplify traditional trading processes and create new market opportunities for all parties involved
                in the dairy industry.
              </Trans>
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                size="large"
                startIcon={<ListItemIcon style={{ height: 24 }} />}
              >
                <Trans>About the team</Trans>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
