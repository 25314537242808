import React from 'react';
import { AppBar, Container, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { ToolbarDesktop } from './ToolbarDesktop';
import { ToolbarMobile } from './ToolbarMobile';

const getClasses = makeStyles({
  appbar: {
    marginRight: 'auto',
    marginLeft: 'auto',
    backgroundColor: '#fff',
    color: '#000',
  },
  container: { paddingLeft: 0, paddingRight: 0 },
  spacer: { height: 110 },
});

export const Header = () => {
  const classes = getClasses();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <AppBar position="fixed" className={classes.appbar}>
        <Container maxWidth="lg" className={classes.container}>
          <ToolbarDesktop isDesktop={!isMobile} />
          <ToolbarMobile isMobile={isMobile} />
        </Container>
      </AppBar>
      <div className={classes.spacer} />
    </>
  );
};
