import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';
import { Language } from '../../../../../translation';

const getClasses = makeStyles({
  root: {
    flex: 1,
    textAlign: 'center',
    display: 'flex',
  },
  language: {
    display: 'flex',
    cursor: 'pointer',
    padding: '6px 8px',
  },
  languageText: {
    alignSelf: 'center',
  },
  flag: {
    height: 25,
    marginRight: 5,
  },
  flagInactive: {
    filter: 'grayscale(1)',
  },
});
const LANGUAGE_LABEL_MAP: Record<Language, string> = {
  'de-DE': 'Deutsch',
  'en-US': 'English',
  'pl-PL': 'Polski',
};
const LANGUAGE_LOGO_URL_MAP: Record<Language, string> = {
  'de-DE': 'https://assets.milkscout-stage.eu/flags/de.png',
  'en-US': 'https://assets.milkscout-stage.eu/flags/us.png',
  'pl-PL': 'https://assets.milkscout-stage.eu/flags/pl.png',
};
export interface LanguageButtonProps {
  language: Language;
  endIcon?: ReactElement;
  active?: boolean;
}

export const LanguageItem = ({ language, endIcon, active = true }: LanguageButtonProps) => {
  const classes = getClasses();
  return (
    <div className={classes.root}>
      <span className={classes.language}>
        <img
          className={`${classes.flag} ${active ? '' : classes.flagInactive}`}
          src={LANGUAGE_LOGO_URL_MAP[language]}
          alt="language"
        />
        <span className={classes.languageText}>{LANGUAGE_LABEL_MAP[language]}</span>
      </span>
      {endIcon}
    </div>
  );
};
