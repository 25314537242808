/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Container } from '@material-ui/core';
import { useQueryParams } from '@milkscout/react';
import { PageLayout } from '../layout';
import { Header } from './components/Header';
import { Accordion } from './components/Accordion';
import { getNsTrans } from '../../translation';

const Trans = getNsTrans('faq-page');
const TransDairies = getNsTrans('faq-diaries');
const TransHauler = getNsTrans('faq-hauler');

export const getFaqCosts = (expanded: boolean = false) => (
  <Accordion foreExpanded={expanded} question={<TransDairies>How much does it cost to use MilkScout?</TransDairies>}>
    <TransDairies tOptions={{ price: process.env.REACT_APP_MILKSCOUT_BUYER_PRICE }}>
      {
        'Registration, placing offers and selling are free of charge. The buyer is charged € {{price}} per successfully brokered transaction.'
      }
    </TransDairies>
  </Accordion>
);

export const getFaqProducts = (expanded: boolean = false) => (
  <Accordion foreExpanded={expanded} question={<TransDairies>Which products are traded?</TransDairies>}>
    <TransDairies>
      Currently, more than 10 raw materials can be offered on MilkScout. These include milk, cream, whey, buttermilk and
      concentrates with the usual product characteristics.
    </TransDairies>
  </Accordion>
);

export const getFaqTransport = (expanded: boolean = false) => (
  <Accordion foreExpanded={expanded} question={<TransDairies>How does transport via MilkScout works?</TransDairies>}>
    <TransDairies>
      Once the purchase has been successfully completed, the buyer can optionally create a transport order via
      MilkScout. The transport companies then have two hours to bid on the offer. Through the individual selection of
      haulage companies, the settings can be used to restrict which haulage companies should receive transport requests.
    </TransDairies>
  </Accordion>
);

export const getFaqSell = (expanded: boolean = false) => (
  <Accordion foreExpanded={expanded} question={<TransDairies>How can I sell via the platform?</TransDairies>}>
    <TransDairies>
      Anyone who wants to sell a product creates an offer and specifies the following data: Raw material, quantity,
      price, collection date, quality requirements. The offer is then displayed on the marketplace and forwarders can
      bid on it. can bid on it.
    </TransDairies>
  </Accordion>
);

export const FaqPage = () => {
  const { expanded } = useQueryParams<{ expanded: string }>();
  return (
    <PageLayout>
      <Header>
        <Trans>Frequently asked questions for dairy</Trans>
      </Header>
      <Container maxWidth="lg" style={{ marginTop: 29, marginBottom: 20, maxWidth: 1000 }}>
        <Accordion foreExpanded={!!expanded} question={<TransDairies>What is MilkScout?</TransDairies>}>
          <TransDairies>
            MilkScout is a platform where companies can trade milk and dairy products among themselves (B2B).
          </TransDairies>
        </Accordion>
        <Accordion foreExpanded={!!expanded} question={<TransDairies>Who can trade on the platform?</TransDairies>}>
          <TransDairies>Employees of companies that trade or process milk or dairy raw materials.</TransDairies>
        </Accordion>
        <Accordion
          foreExpanded={!!expanded}
          question={<TransDairies>Which companies are allowed to register?</TransDairies>}
        >
          <TransDairies>Dairies, food companies, buyers and sellers of dairy products.</TransDairies>
        </Accordion>
        {getFaqProducts(!!expanded)}
        <Accordion
          foreExpanded={!!expanded}
          question={<TransDairies>Can I add more products to the item list?</TransDairies>}
        >
          <TransDairies>
            We would be happy to extend the product list for you. Please contact our support team with the relevant
            product specification.
          </TransDairies>
        </Accordion>
        <Accordion foreExpanded={!!expanded} question={<TransDairies>You already trade milk?</TransDairies>}>
          <TransDairies tOptions={{ email: process.env.REACT_APP_MILKSCOUT_INFO_MAIL }}>
            Do you trade milk or milk concentrates and would like to become a MilkScout partner? For more information,
            please contact <a href={`mailto:${process.env.REACT_APP_MILKSCOUT_INFO_MAIL}`}>{'{{email}}'}</a>.
          </TransDairies>
        </Accordion>
        <Accordion foreExpanded={!!expanded} question={<TransDairies>How secure is your data?</TransDairies>}>
          <TransDairies>
            We take data protection very seriously! MilkScout GmbH is state-of-the-art thanks to excellent IT expertise
            and uses SSL or TLS encryption to protect confidential content. All data is stored hosted on servers within
            the European Union.
          </TransDairies>
        </Accordion>
        {getFaqCosts(!!expanded)}
        <Accordion
          foreExpanded={!!expanded}
          question={
            <TransDairies>
              Will I receive an invoice with the MilkScout service fee after each transaction?
            </TransDairies>
          }
        >
          <TransDairies>
            No, you will receive a comprehensive monthly invoice for all contracts closed during the period.
          </TransDairies>
        </Accordion>
        <Accordion foreExpanded={!!expanded} question={<TransDairies>Payment options</TransDairies>}>
          <TransDairies>
            Currently we only accept payments by bank transfer. If you prefer another payment method, please contact us.
          </TransDairies>
        </Accordion>
        <Accordion
          foreExpanded={!!expanded}
          question={<TransDairies>How is the quality of the products offered ensured?</TransDairies>}
        >
          <TransDairies>
            The GTC apply to all transactions concluded via the MilkScout marketplace. Product specifications are
            attached to the available products. If a product deviates from the specification, this must be clearly
            described in the comment field or the seller must or the seller must add his own specification.
          </TransDairies>
        </Accordion>
        <Accordion foreExpanded={!!expanded} question={<TransDairies>Can I edit or delete my offers?</TransDairies>}>
          <TransDairies>
            Offers can be removed from the marketplace free of charge at any time, as long as they have not yet been
            sold.
          </TransDairies>
        </Accordion>
        <Accordion foreExpanded={!!expanded} question={<TransDairies>Is a purchase irrevocable?</TransDairies>}>
          <TransDairies>
            Yes, once the buyer sends the purchase request and the seller confirms it, the purchase arrangement is
            binding. The seller and the buyer will immediately receive a confirmation by mail.
          </TransDairies>
        </Accordion>
        <Accordion
          foreExpanded={!!expanded}
          question={<TransDairies>How can I be sure that my contractors are reliable?</TransDairies>}
        >
          <TransDairies>
            All registered users have been verified by MilkScout. Any misconduct can be reported to MilkScout Support.
            Unreliable users will be excluded from using the platform.
          </TransDairies>
        </Accordion>
        <Accordion
          foreExpanded={!!expanded}
          question={<TransDairies>What information is required for registration?</TransDairies>}
        >
          <TransDairies>
            In addition to a business e-mail address, information regarding the company must be provided.
          </TransDairies>
        </Accordion>
        <Accordion
          foreExpanded={!!expanded}
          question={<TransDairies>Is it possible to specify more than one contact person per company?</TransDairies>}
        >
          <TransDairies>
            Yes, there are no restrictions on the number of users. You can register as many e-mail addresses as you
            wish.
          </TransDairies>
        </Accordion>
        {getFaqSell(!!expanded)}
        <Accordion foreExpanded={!!expanded} question={<TransDairies>Who can see my company profile?</TransDairies>}>
          <TransDairies>
            Your profile is only visible to you and the MilkScout team. Contact details are only transmitted in the case
            of commercial transactions, such as a purchase enquiry from a buyer to the seller.
          </TransDairies>
        </Accordion>
        <Accordion foreExpanded={!!expanded} question={<TransDairies>What is &quot;MilkMatch&quot;?</TransDairies>}>
          <TransDairies>
            MilkMatch is MilkScout&sbquo;s raw material matching tool. Via the tool, trading partners can easily
            reconcile the quantity and ingredients online. It can also be used for trading transactions outside
            MilkScout. All that is needed is the email address of the trading partner.
          </TransDairies>
        </Accordion>
        <Accordion foreExpanded={!!expanded} question={<TransDairies>Are offers displayed anonymously?</TransDairies>}>
          <TransDairies>
            The display of the offer is completely anonymous in the first step. Only after the binding purchase request,
            the buyer learns the data of the trading partner.
          </TransDairies>
        </Accordion>
        <Accordion
          foreExpanded={!!expanded}
          question={<TransDairies>Can I narrow down my trading partners (suppliers and customers)?</TransDairies>}
        >
          <TransDairies>
            After successful purchase, the buyer can optionally create a transport order via MilkScout. Then the haulers
            have 2 hours to bid on the offer. By the individual selection of haulers can be limited in the settings,
            which forwarding companies should receive transport requests.
          </TransDairies>
        </Accordion>
        {getFaqTransport(!!expanded)}
        <Accordion
          foreExpanded={!!expanded}
          question={<TransDairies>How are the freight costs presented?</TransDairies>}
        >
          <TransDairies>
            The price shown is an estimate. The actual freight costs can only be determined after the transport order
            has been placed.
          </TransDairies>
        </Accordion>
        <Accordion
          foreExpanded={!!expanded}
          question={<TransDairies>What does the price quoted by the seller include?</TransDairies>}
        >
          <TransDairies>
            The seller quotes the prices for the raw material without freight ex works. The buyer organises the
            transport. This can be done automatically via the platform or independently. The offers are displayed with
            an estimated transport price displayed in the marketplace.
          </TransDairies>
        </Accordion>
        <Accordion
          foreExpanded={!!expanded}
          question={<TransDairies>Does MilkScout act as a trader or intermediary?</TransDairies>}
        >
          <TransDairies>
            MilkScout is purely an intermediary platform. Invoicing takes place between the two trading partners.
          </TransDairies>
        </Accordion>
      </Container>
      <Header>
        <Trans>Frequently asked questions for hauler</Trans>
      </Header>
      <Container maxWidth="lg" style={{ marginTop: 29, marginBottom: 20, maxWidth: 1000 }}>
        <Accordion
          foreExpanded={!!expanded}
          question={
            <TransHauler>Which companies are allowed to register as freight forwarders on MilkScout?</TransHauler>
          }
        >
          <TransHauler>
            All forwarding companies specializing in the transportation of liquid food products.
          </TransHauler>
        </Accordion>
        <Accordion foreExpanded={!!expanded} question={<TransHauler>How are transport orders arranged?</TransHauler>}>
          <TransHauler>
            Dairies can post transport orders. Freight forwarders can then submit offers free of charge within two
            hours. The transport offer includes all relevant information (pick-up location, destination, raw material,
            quantity, cleaning information, etc.). After two hours, a notification is sent by e-mail whether the offer
            has been acquired or not. Only then is there an exchange of contact information.
          </TransHauler>
        </Accordion>
        <Accordion
          foreExpanded={!!expanded}
          question={<TransHauler>What does MilkScout cost for haulers?</TransHauler>}
        >
          <TransHauler>
            Registration and use are free of charge. Bidding on transport orders is not associated with any costs. A
            small brokerage fee is only charged upon the successful acquisition of an order.
          </TransHauler>
        </Accordion>
        <Accordion foreExpanded={!!expanded} question={<TransHauler>Payment options</TransHauler>}>
          <TransHauler>
            At the end of the month, MilkScout will send you an invoice for the orders placed by MilkScout. At present,
            we only accept payments by bank transfer. If you prefer another payment method, please contact us. please
            contact us.
          </TransHauler>
        </Accordion>
        <Accordion foreExpanded={!!expanded} question={<TransHauler>Who is the client for the transport?</TransHauler>}>
          <TransHauler>
            MilkScout only acts as an intermediary. The buyer dairy is the order giver. Invoices are issued to the
            dairy.
          </TransHauler>
        </Accordion>
        <Accordion
          foreExpanded={!!expanded}
          question={<TransHauler>How can I use MilkScout as an advertising platform for my logo?</TransHauler>}
        >
          <TransHauler tOptions={{ email: process.env.REACT_APP_MILKSCOUT_INFO_MAIL }}>
            Contact <a href={`mailto:${process.env.REACT_APP_MILKSCOUT_INFO_MAIL}`}>{'{{email}}'}</a>
          </TransHauler>
        </Accordion>
      </Container>
    </PageLayout>
  );
};
