import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { parseLanguage } from '../translation';

export const Helmet = () => {
  const { i18n } = useTranslation();
  const language = parseLanguage(i18n.language);

  return <ReactHelmet htmlAttributes={{ lang: language }} />;
};
