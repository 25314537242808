import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import { Footer, Header } from './components';

const getClasses = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export interface PageLayoutProps {
  trackingDisabled?: boolean;
}

export const PageLayout = ({ children, trackingDisabled = false }: PropsWithChildren<PageLayoutProps>) => {
  const classes = getClasses();
  // TODO: add banner
  console.log({ trackingDisabled });
  return (
    <div className={classes.root}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};
