/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useCallback, useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ModalLayout } from './modal/ModalLayout';
import { ModalFooter } from './modal/ModalFooter';
import { Modal } from './modal/Modal';
import { getNsTrans } from '../translation';

const Trans = getNsTrans('external-link');

const getClasses = makeStyles(({ palette }: Theme) => ({
  text: {
    fontSize: 16,
    textAlign: 'center',
  },
  colored: {
    color: palette.primary.main,
  },
  ellipsis: { display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
}));
export interface ExternalLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  ellipsis?: boolean;
}

export const ExternalLink = ({ ellipsis = false, className = '', ...props }: ExternalLinkProps) => {
  const classes = getClasses();
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const handleOnLinkForward = useCallback(() => {
    const { current } = anchorRef;
    if (current) {
      current.click();
      setOpen(false);
    }
  }, [anchorRef]);

  return (
    <span>
      <a
        target="_blank"
        rel="noopener noreferrer"
        {...props}
        className={`${className} ${ellipsis ? classes.ellipsis : ''}`}
        onClick={(evt) => {
          evt.preventDefault();
          if (props.href) {
            setOpen(true);
          }
        }}
      />
      <a ref={anchorRef} target="_blank" rel="noopener noreferrer" {...props} style={{ display: 'none' }} />
      <Modal open={open}>
        <ModalLayout
          onClose={() => setOpen(false)}
          title={
            <div className={classes.colored}>
              <Trans>External link detected</Trans>
            </div>
          }
        >
          <div className={classes.text}>
            <div>
              <Trans>Do you want to follow this link?</Trans>
            </div>
            <div>{props.href}</div>
          </div>
          <ModalFooter>
            <div className={classes.text}>
              <Button size="large" variant="outlined" color="primary" onClick={() => handleOnLinkForward()}>
                <Trans>Yes</Trans>
              </Button>
              <span> </span>
              <Button size="large" variant="contained" color="primary" onClick={() => setOpen(false)}>
                <Trans>No</Trans>
              </Button>
            </div>
          </ModalFooter>
        </ModalLayout>
      </Modal>
    </span>
  );
};
