import React, { CSSProperties } from 'react';

export interface PaperPlaneProps {
  style?: CSSProperties;
  className?: string;
}

export const PaperPlane = ({ style, className }: PaperPlaneProps) => (
  <svg
    version="1.1"
    style={style}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1792.0013 1896.0833"
  >
    <path d="M1764 11q33 24 27 64l-256 1536q-5 29-32 45-14 8-31 8-11 0-24-5l-453-185-242 295q-18 23-49 23-13 0-22-4-19-7-30.5-23.5T640 1728v-349l864-1059-1069 925-395-162q-37-14-40-55-2-40 32-59L1696 9q15-9 32-9 20 0 36 11z" />
  </svg>
);
