/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paragraph } from './components/Paragraph';
import { Section } from './components/Section';
import { ALLGEMEINE_NUTZUNGSBEDINGUNGEN_FILE_PATH } from '../../assets/public.references';
import { DATA_PRIVACY_ROUTE } from '../../page/routes';
import { joinUrl } from './functions/joinUrl';

const getClasses = makeStyles(({ palette }: Theme) => ({
  header: {
    color: palette.primary.main,
  },
}));
export const ContentGerman = () => {
  const classes = getClasses();
  return (
    <>
      <h1 className={classes.header}>
        Allgemeine Nutzungsbedingungen
        <br />
        der MilkScout GmbH{' '}
        <a href={ALLGEMEINE_NUTZUNGSBEDINGUNGEN_FILE_PATH} target="_blank" rel="noopener noreferrer">
          (Orginal in PDF)
        </a>
      </h1>
      <Paragraph number={1}>Marktplatz; ausschließliche Geltung der Nutzungsbedingungen</Paragraph>
      <Section number="1.1">
        Die MilkScout GmbH, Kiem-Pauli-Straße 8, 84036 Landshut (nachfolgend <b>„MilkScout“</b>), bietet Unternehmern im
        Sinne von § 14 BGB und juristischen Personen des öffentlichen Rechts (nachfolgend <b>„Nutzer“</b>) im Rahmen
        eines Nutzungsvertrages die Möglichkeit, ein Business-to-Business Handelssystem auf dem von MilkScout
        betriebenen online-Marktplatz (nachfolgend <b>„Marktplatz“</b>) gemäß den Vorgaben dieser Nutzungsbedingungen zu
        nutzen.
      </Section>
      <Section number="1.2">
        Diese Nutzungsbedingungen enthalten abschließend die zwischen MilkScout und dem Nutzer geltenden Bedingungen für
        die von MilkScout im Rahmen dieses Nutzungsvertrages angebotenen Leistungen. Von diesen Nutzungsbedingungen
        abweichende oder diese ergänzende Regelungen gelten nur dann, wenn diese von MilkScout schriftlich bestätigt
        werden. Mit dem Antrag auf Registrierung gemäß § 3 erkennt der Nutzer diese Nutzungsbedingungen als maßgeblich
        an.
      </Section>
      <Section number="1.3">
        Änderungen dieser Nutzungsbedingungen werden dem Nutzer von MilkScout in Textform (z. B. per E-Mail) mitgeteilt.
        Widerspricht der Nutzer solchen Änderungen nicht innerhalb von 30 Tagen nach Zugang der Mitteilung in Textform
        (z. B. per E-Mail), gelten die Änderungen als vereinbart. Auf das Widerspruchsrecht und die Rechtsfolgen des
        Schweigens wird der Nutzer im Falle der Änderung der Nutzungsbedingungen gesondert hingewiesen. Wenn der Nutzer
        mit den Änderungen nicht einverstanden ist, steht ihm bis zu dem vorgeschlagenen Zeitpunkt des Wirksamwerdens
        der Änderungen ein fristloses Kündigungsrecht zu. Die geänderten Nutzungsbedingungen werden zusätzlich auf der
        MilkScout-Website veröffentlicht.
      </Section>
      <Paragraph number={2}>Leistungsbeschreibung</Paragraph>
      <Section number="2.1">
        Der Marktplatz ist eine Plattform für den Abschluss von Kaufverträgen über Milch, Milchrohstoffe und sonstige
        Milcherzeugnisse (insbesondere Magermilch, Magermilchkonzentrate, Sahne, Molke, Buttermilch, flüssige Laktose;
        nachfolgend: <b>„Produkte“</b>) (nachfolgend <b>„Kaufverträge“</b>) sowie Speditionsverträge über die Versendung
        von Produkten (nachfolgend <b>„Speditionsverträge“</b>; Kaufverträge und Speditionsverträge nachfolgend
        gemeinsam <b>„Transaktionen“</b>).
      </Section>
      <Section number="2.2">
        MilkScout bietet selbst keine Produkte oder Speditionsleistungen an und wird nicht Vertragspartner der
        ausschließlich zwischen den Nutzern dieses Marktplatzes geschlossenen Verträge.
      </Section>
      <Section number="2.3">
        Der Marktplatz verfügt über ein integriertes, automatisiertes Nachrichtensystem zwecks Vereinfachung der
        Kommunikation zwischen den Nutzern wie auch über umfangreiche Funktionalitäten zur Verwaltung und Überwachung
        aller laufenden Geschäftstransaktionen.
      </Section>
      <Section number="2.4">
        <div>MilkScout erbringt nach Maßgabe der nachfolgenden Regelungen die folgenden Leistungen: </div>
        <Section number="2.4.1">
          Bereithaltung der Nutzungsmöglichkeiten des Marktplatzes nach Maßgabe dieser Nutzungsbedingungen;
        </Section>
        <Section number="2.4.2">
          Ermöglichung von Verhandlungen und Vertragsabschlüssen auf dem Marktplatz durch vom Nutzer initiierte
          Ausschreibungen und Auktionen;
        </Section>
        <Section number="2.4.3">
          Schaffung von Informations- und Kommunikationsmöglichkeiten unter den Nutzern, insbesondere zur finalen
          Abstimmung von Preisen in Abhängigkeit von den Liefermengen und den Spezifikationen der Produkte nach
          Ausführung der Kaufverträge (sog. MilkMatch).{' '}
        </Section>
      </Section>
      <Section number="2.5">
        Der Anspruch von Nutzern auf Nutzung des Marktplatzes besteht nur im Rahmen des aktuellen Stands der Technik.
        MilkScout schränkt seine Leistungen zeitweilig ein, wenn dies im Hinblick auf Kapazitätsgrenzen, die Sicherheit
        oder Integrität der Server oder zur Durchführung technischer Maßnahmen erforderlich ist, und dies der
        ordnungsgemäßen oder verbesserten Erbringung der Leistungen dient (Wartungsarbeiten). MilkScout berücksichtigt
        in diesen Fällen die berechtigten Interessen der Nutzer und wird diese nach Möglichkeit vorab informieren.
      </Section>
      <Section number="2.6">
        MilkScout behält sich das Recht vor, Inhalt und Struktur des Marktplatzes sowie die dazugehörigen
        Benutzeroberflächen zu ändern oder zu erweitern, wenn hierdurch die Zweckerfüllung des mit dem Nutzer
        geschlossenen Vertrages nicht oder nicht erheblich beeinträchtigt wird. MilkScout wird die Nutzer des
        Marktplatzes über die Änderungen entsprechend informieren.{' '}
      </Section>
      <Paragraph number={3}>Registrierung zum Marktplatz; Abschluss Nutzungsvertrag</Paragraph>
      <Section number="3.1">
        Voraussetzung für die Nutzung des Marktplatzes ist die Registrierung des Nutzers. Der Marktplatz steht nur
        Unternehmern im Sinne von § 14 BGB und juristischen Personen des öffentlichen Rechts zur Verfügung. Ein Anspruch
        auf Registrierung oder Nutzung des Marktplatzes besteht nicht.
      </Section>
      <Section number="3.2">
        Der Nutzer hat die in der Maske zur Registrierung genannten Angaben (u.a. seine E-Mailadresse, sein Passwort,
        seine Firma, seinen Unternehmensstandort, seine Adresse, seine Telefonnummer und seine Steuernummer) zu machen
        sowie anzugeben, ob er den Marktplatz als „Molkerei“ für den Einkauf oder Verkauf von Produkten (nachfolgend
        <b>„Kaufnutzer“</b>) oder als „Spediteur“ als Anbieter von Speditionsleistungen (nachfolgend <b>„Spediteur“</b>)
        nutzen möchte.
      </Section>
      <Section number="3.3">
        Die Annahme des Registrierungsantrags erfolgt durch Registrierungsbestätigung per E-Mail. Durch die
        Registrierung kommt ein Nutzungsvertrag auf unbestimmte Zeit zwischen MilkScout und dem jeweiligen Nutzer nach
        diesen Nutzungsbedingungen zustande.
      </Section>
      <Section number="3.4">
        Kaufnutzer können jederzeit weitere Daten in einer dafür vorgesehenen Datenmaske „Unternehmenseinstellungen“ auf
        dem Marktplatz angeben (z. B. Verladezeiten sowie Benennung konkreter anderer Nutzer, mit denen Kaufverträge
        oder Speditionsverträge abgeschlossen werden können oder deren Angebote dem Kaufnutzer nicht angezeigt werden
        sollen).
      </Section>
      <Section number="3.5">
        Spediteure können jederzeit weitere Daten in einer dafür vorgesehenen Datenmaske „Unternehmenseinstellungen“ auf
        dem Marktplatz angeben (z. B. Zertifikate).
      </Section>
      <Section number="3.6">
        Der Nutzer steht dafür ein, dass die von ihm, insbesondere im Rahmen seines Antrages auf Registrierung,
        gegenüber MilkScout und anderen Nutzern gemachten Angaben richtig sind. Er verpflichtet sich, MilkScout alle
        künftigen Änderungen der gemachten Angaben unverzüglich auf dem Marktplatz anzugeben.
      </Section>
      <Paragraph number={4}>Login</Paragraph>
      <Section number="4.1">
        Jedem Nutzer steht es frei, auf dem Marktplatz mit mehreren Mitarbeitern und Logins zu agieren. Der Nutzer
        werden die Erklärungen und Handlungen seiner Mitarbeiter zugerechnet.
      </Section>
      <Section number="4.2">
        Alle Logins sind individualisiert und dürfen nur von der jeweils berechtigten Person verwendet werden. Login und
        Passwort sind geheim zu halten und vor dem unberechtigten Zugriff Dritter zu schützen. Der Nutzer ist für die
        Geheimhaltung der Mitarbeiter-Logins verantwortlich und wird seine Mitarbeiter entsprechend anweisen. Bei
        Verdacht des Missbrauchs durch einen Dritten wird der Nutzer MilkScout hierüber unverzüglich informieren. Sobald
        MilkScout von der unberechtigten Nutzung Kenntnis erlangt, wird MilkScout den Zugang des unberechtigten Nutzers
        sperren. MilkScout behält sich das Recht vor, Login und Passwort eines Nutzers oder Mitarbeiters zu ändern; in
        einem solchen Fall wird MilkScout den Nutzer hierüber unverzüglich informieren.
      </Section>
      <Section number="4.3">
        MilkScout ist berechtigt, den Zugang des Nutzers insgesamt oder in Teilbereichen vorübergehend oder dauerhaft zu
        sperren, wenn konkrete Anhaltspunkte dafür vorliegen, dass der Nutzer gegen diese Nutzungsbedingungen und/oder
        gegen geltendes Recht verstößt, und wenn das berechtigte Interesse von MilkScout an der Sperrung das Interesse
        des Nutzers am Zugang zum Marktplatz überwiegt; in einem solchen Fall wird MilkScout den Nutzer hierüber
        unverzüglich informieren.
      </Section>
      <Paragraph number={5}>Datenschutz; Datenverarbeitung</Paragraph>
      <Section number="5.1 ">
        Mit der Registrierung gemäß § 3 übernimmt der Nutzer gegenüber MilkScout und allen anderen Nutzern die Gewähr,
        dass bezüglich der von ihm übertragenen Daten die datenschutzrechtlichen Erfordernisse durch den Nutzer
        eingehalten werden, und stellt MilkScout von jeglichen Ansprüchen, auch öffentlich-rechtlicher Natur, frei.
        Insbesondere muss der Nutzer die für ihn auf dem Marktplatz handelnden Personen (insbesondere Mitarbeiter) über
        die dabei erforderlichen Datenverarbeitungsprozesse durch MilkScout gemäß Datenschutzerklärung von MilkScout
        unter {joinUrl('milkscout.eu', DATA_PRIVACY_ROUTE)} informieren und die gegebenenfalls notwendige Einwilligung
        dieser handelnden Personen einholen, bevor deren personenbezogene Daten im Rahmen der Einrichtung von
        Mitarbeiter-Logins oder auf sonstige Weise in dem Marktplatz eingestellt werden.
      </Section>
      <Section number="5.2 ">
        Die Server von MilkScout sind dem Stand der Technik entsprechend, insbesondere durch Firewalls, gesichert; dem
        Nutzer ist jedoch bekannt, dass für alle Teilnehmer die Gefahr besteht, dass übermittelte Daten im
        Übertragungsweg ausgelesen oder gelöscht werden können. Dies gilt nicht nur für den Austausch von Informationen
        über E-Mail, die das System verlassen, sondern auch für das integrierte Nachrichtensystem sowie für alle
        sonstigen Übertragungen von Daten. Die Vertraulichkeit und der Bestand der im Rahmen der Nutzung des
        Marktplatzes übermittelten Daten kann daher nicht gewährleistet werden.
      </Section>
      <Section number="5.3 ">
        Der Nutzer willigt darin ein, dass MilkScout Informationen und nicht personenbezogene Daten über den Verlauf von
        Transaktionen sowie das Verhalten von Nutzern bei der Durchführung von Transaktionen in anonymisierter Form
        speichert und ausschließlich in dieser anonymisierten Form für Marketingzwecke, z. B. für die Erstellung von
        Statistiken und Präsentationen, nutzen darf.
      </Section>
      <Section number="5.4 ">
        <div>
          MilkScout ist berechtigt, während der Laufzeit dieses Vertrages die im Zusammenhang mit der Geschäftsbeziehung
          vom Nutzer erhaltenen nicht personenbezogenen Daten zu bearbeiten und zu speichern. Im Einzelnen willigt der
          Nutzer darin ein, dass MilkScout:
        </div>
        <Section number="5.4.1">
          die vom Nutzer im Rahmen des Registrierungsantrags gemachten Angaben zu Unternehmensdaten und Rechnungsdaten
          sowie entsprechende vom Nutzer mitgeteilte Aktualisierungen speichert und bearbeitet;
        </Section>
        <Section number="5.4.2">
          die vom Nutzer im Zusammenhang mit der von ihm im Rahmen der Registrierung gemäß § 3 in den Marktplatz
          eingepflegten Daten speichert und nach Maßgabe der Regelungen in § 6 und § 7 dieser Nutzungsbedingungen im
          öffentlichen Bereich des Marktplatzes für die vom Nutzer angegebenen anderen Nutzer zum Abruf bereit hält;
        </Section>
        <Section number="5.4.3">
          nicht personenbezogene Daten über den Inhalt der Transaktionen speichert und im öffentlichen Bereich des
          Marktplatzes für die betreffenden Nutzer zum Abruf bereit hält.
        </Section>
      </Section>
      <Section number="5.5 ">
        MilkScout wird im Übrigen alle den Nutzer betreffenden Daten nur nach Maßgabe dieser Nutzungsbedingungen
        verwenden. MilkScout behält sich vor, hiervon abzuweichen, wenn MilkScout aufgrund gesetzlicher oder
        behördlicher Anordnungen Daten des Nutzers offen legen muss.
      </Section>
      <Section number="5.6 ">
        Hinsichtlich der Verarbeitung personenbezogener Daten wird auf die gesonderte Datenschutzerklärung von MilkScout
        unter {joinUrl('milkscout.eu', DATA_PRIVACY_ROUTE)} verwiesen.
      </Section>
      <Section number="5.7 ">
        Nutzer dürfen Adressen, E-Mail-Adressen und sonstige Kontaktdaten, die sie durch die Nutzung des Marktplatzes
        erhalten haben, für keine anderen Zwecke nutzen, als für die vertragliche und vorvertragliche Kommunikation.
        Insbesondere ist es verboten, diese Daten weiterzuverkaufen oder sie für die Zusendung von Werbung zu nutzen, es
        sei denn, der jeweilige Nutzer hat diesem nach Maßgabe der geltenden gesetzlichen Bestimmungen ausdrücklich
        vorher zugestimmt bzw. einer ohne Einwilligung zulässigen Nutzung der Kontaktdaten zu Werbezwecken nicht
        widersprochen.
      </Section>
      <Section number="5.8 ">
        Nach Beendigung des Nutzungsvertrages werden die von dem jeweiligen Nutzer bereitgestellten oder durch seine
        Nutzung des Marktplatzes generierten Daten gelöscht. Abweichend hiervon bewahrt MilkScout diese Informationen
        auch nach Vertragsende auf, soweit MilkScout hierzu gesetzlich verpflichtet ist oder hieran ein berechtigtes
        Interesse hat. Aggregierte und anonymisierte Daten, die durch die Nutzung generiert wurden (z. B. Statistiken
        über Transaktionen) werden grundsätzlich auch nach Vertragsende weiterhin aufbewahrt.
      </Section>
      <Section number="5.9 ">
        Für den Verlust von Daten haftet MilkScout nur dann, wenn ein solcher Verlust durch angemessene
        Datensicherungsmaßnahmen seitens des Nutzers nicht vermeidbar gewesen wäre. Der Nutzer verpflichtet sich, für
        eine angemessene Sicherung sämtlicher im Rahmen der Nutzung des Marktplatzes von ihm bereitgestellten oder
        erlangten Daten Sorge zu tragen.
      </Section>
      <Section number="5.10">
        MilkScout ist berechtigt, die vom Nutzer im Zusammenhang mit der Nutzung des Marktplatzes übermittelte
        E-Mail-Adresse für die Übermittlung von Informationen über ähnliche eigene Waren- und Dienstleistungsangebote zu
        nutzen (§ 7 Abs. 3 Nr. 4 UWG). Dieser Verwendung der E-Mail-Adresse für die Bestandskundenwerbung kann der
        Nutzer jederzeit durch Übersendung einer E-Mail an info@milkscout.eu widersprechen.
      </Section>
      <Paragraph number={6}>Abschluss von Kaufverträgen auf dem Marktplatz</Paragraph>
      <Section number="6.1">
        Kaufnutzer, die Produkte verkaufen wollen (nachfolgend <b>„Verkäufer“</b>), haben die Möglichkeit,
        Ausschreibungen zu eröffnen und dazu (ggf. nach individuellen Kriterien gemäß Ziffer 3.4 ausgewählte) andere
        Kaufnutzer einzuladen, die Produkte kaufen wollen (nachfolgend <b>„Käufer“</b>).
      </Section>
      <Section number="6.2">
        Verkäufer haben bei der Eröffnung einer Ausschreibung das Produkt, die Menge, den Preis als Fixpreis oder als
        Flexpreis, der sich stündlich reduziert, die Produkteigenschaften, den Abholort und den Abholtag anzugeben
        (nachfolgend <b>„Verkaufsanfrage“</b>). Eine Beschreibung der Produkte erfolgt im Hinblick auf die gesetzlichen
        Begriffsbestimmungen und die gesetzlichen Anforderungen an Herstellung, Kennzeichnung und Verpackung,
        insbesondere nach der VO (EU) 1308/2013 und der MilcherzeugnisVO; der Verkäufer kann bei jedem Produkt zwischen
        der von MilkScout zur Verfügung gestellten Standardspezifikation und der eigenen Spezifikation wählen. Die
        Verkaufsanfrage wird den Kaufnutzern des (ggf. gemäß Ziffer 3.4 ausgewählten Adressatenkreises) anonym – das
        heißt ohne Angabe der Firma und der Kontaktdaten des Verkäufers - offengelegt. Sofern er die Verkaufsanfrage
        nicht storniert oder ändert, ist der Verkäufer bis 24:00 Uhr des in dieser angegebenen Abholtages an die in
        dieser gemachten Angaben gebunden, ein rechtlich verbindliches Angebot stellt eine Verkaufsanfrage jedoch nicht
        dar.
      </Section>
      <Section number="6.3">
        Käufer können auf die Verkaufsanfrage verbindliche Angebote abgeben, an die sie für acht Stunden während eines
        Zeitraums von 8:00 Uhr bis 17:00 Uhr, montags bis freitags, ab der Abgabe gebunden sind (Beispiele: (i) Gibt ein
        Käufer ein Angebot am Donnerstag um 16:00 Uhr ab, ist er an dieses bis Freitag, 15:00 Uhr, gebunden; (ii) gibt
        er das Angebot um 8:30 Uhr ab, ist er an dieses bis 16:30 Uhr des selben Tages gebunden). Mit der Abgabe eines
        Angebotes, werden dem Käufer die vollständigen Angaben zum Verkäufer und des Kontaktdaten offengelegt und die
        Verkaufsanfrage wird nicht mehr auf dem Marktplatz angezeigt.
      </Section>
      <Section number="6.4">
        Der Verkäufer ist frei in der Wahl, ob er das Angebot annimmt. Ein Vertrag kommt zustande, wenn der Verkäufer
        das Angebot eines Käufers annimmt. Nach dem Vertragsschluss erhalten Käufer und Verkäufer eine
        Bestätigungs-E-Mail, in welcher die wesentlichen Eckdaten des Kaufvertrages enthalten sind.
      </Section>
      <Paragraph number={7}>Abschluss von Speditionsverträgen auf dem Marktplatz</Paragraph>
      <Section number="7.1">
        Käufer, die Speditionsleistungen in Anspruch nehmen wollen (nachfolgend <b>„Versender“</b>), haben die
        Möglichkeit, Auktionen zu eröffnen, bei denen (ggf. nach individuellen Kriterien gemäß Ziffer 3.4 ausgewählte)
        Spediteure Gebote abgeben können.
      </Section>
      <Section number="7.2">
        Versender haben bei der Eröffnung einer Auktion das Produkt, die Menge, den Höchstpreis, den Abhol- und den
        Lieferort sowie etwaige weitere Hinweise zur Versendung anzugeben (nachfolgend <b>„Versendungsangebot“</b>). Das
        Versendungsangebot wird den Spediteuren des (ggf. gemäß Ziffer 3.4 ausgewählten Adressatenkreises) offengelegt.
        Das Versendungsangebot ist verbindlich und der Versender ist für den Auktionszeitraum an die in diesem gemachten
        Angaben gebunden. Der Auktionszeitraum beträgt drei Stunden nach Einstellung eines Versendungsangebots auf dem
        Marktplatz während eines Zeitraums von 8:00 Uhr bis 17:00 Uhr (Beispiele: (i) Wird ein Versendungsangebot am
        Donnerstag um 16:00 Uhr eingestellt, endet der Auktionszeitraum Freitag, 10:00 Uhr; (ii) wird ein
        Versendungsangebot um 9:30 Uhr eingestellt, endet der Auktionszeitraum um 12:30 Uhr des selben Tages).
      </Section>
      <Section number="7.3">
        Spediteure können auf das Versendungsangebot verbindliche Gebote abgeben, die unter dem von dem Versender
        angegebenen Höchstpreis liegen müssen. Mit dem Gebot nimmt der Spediteur das Versendungsangebot des Versenders
        unter der aufschiebenden Bedingung an, dass der Spediteur nach Ablauf des Auktionszeitraums Tiefstbietender ist.
        Spediteure sind an ihre Gebote bis zum Ablauf des in Ziffer 7.2 beschriebenen Auktionszeitraums gebunden. Der
        Speditionsvertrag kommt mit dem Spediteur zustande, der zum Ablauf des Auktionszeitraums den niedrigsten Preis
        geboten hat. Nach dem Vertragsschluss erhalten Versender und Spediteur eine Bestätigungs-E-Mail, in welcher die
        wesentlichen Eckdaten des Speditionsvertrages enthalten sind.
      </Section>
      <Paragraph number={8}>Abwicklung von auf dem Marktplatz abgeschlossenen Kauf- und Speditionsverträgen</Paragraph>
      <Section number="8.1">
        Die Abwicklung von auf dem Marktplatz geschlossenen Kauf- und Speditionsverträgen ist alleinige Angelegenheit
        der jeweiligen Nutzer. MilkScout übernimmt für die auf den Marktplätzen geschlossenen Verträge weder eine
        Garantie für die Erfüllung der auf den Marktplätzen zwischen den Nutzern geschlossenen Verträge noch eine
        Haftung für Sach- oder Rechtsmängel der gehandelten Güter und Dienstleistungen oder sonstige Leistungsstörungen.
        MilkScout trifft keinerlei Pflicht, für die Erfüllung der zwischen den Nutzern zustande gekommenen Verträge zu
        sorgen.
      </Section>
      <Section number="8.2">
        MilkScout übernimmt keine Gewähr für die wahre Identität und die Verfügungsbefugnis der Nutzer. Bei Zweifeln
        sind beide Vertragspartner gehalten, sich in geeigneter Weise über die wahre Identität sowie die
        Verfügungsbefugnis des anderen Vertragspartners zu informieren.
      </Section>
      <Paragraph number={9}>Transaktionsgebühren für abgeschlossene Kauf- und Speditionsverträge</Paragraph>
      <Section number="9.1">
        Für die Nutzung des Marktplatzes und die Vermittlung des Abschlusses eines Kaufvertrages erhebt MilkScout von
        dem jeweiligen Käufer eine Vergütung.
      </Section>
      <Section number="9.2">
        Für die Nutzung des Marktplatzes und die Vermittlung des Abschlusses eines Speditionsvertrages erhebt MilkScout
        von dem jeweiligen Spediteur eine Vergütung.
      </Section>
      <Section number="9.3">
        Die Höhe der einzelnen Vergütungssätze richtet sich nach der jeweils aktuellen Preisliste, die unter
        www.milkscout.eu abrufbar ist. MilkScout kann die Vergütungssätze jederzeit mit Wirkung für die Zukunft ändern.
        Änderungen der Vergütungssätze werden den Nutzern rechtzeitig vor dem Inkrafttreten mitgeteilt.
      </Section>
      <Section number="9.4">
        MilkScout stellt die angefallenen Vergütungen gegenüber den zahlungspflichtigen Nutzern monatlich in Rechnung.
        MilkScout informiert den zahlungspflichtigen Nutzer per E-Mail über die Bereitstellung der jeweiligen Rechnung.
        Die vollständige Rechnung kann der zahlungspflichtige Nutzer über sein Login abrufen. Sämtliche vom Nutzer an
        MilkScout zu zahlenden Vergütungen sind gegen ordnungsgemäße Rechnungstellung zuzüglich der gesetzlichen
        Umsatzsteuer zu zahlen.
      </Section>
      <Section number="9.5">
        Zahlungspflichtige Nutzer kommen ohne weitere Mahnung nach einem Ablauf von 30 Tagen nach Mitteilung des
        Rechnungsbetrags in Verzug.
      </Section>
      <Section number="9.6">
        Zur Aufrechnung gegenüber MilkScout ist der Nutzer nur mit unbestrittenen oder rechtskräftigen Gegenforderungen
        berechtigt.
      </Section>
      <Paragraph number={10}>Nutzung des Marktplatzes, Inhalte</Paragraph>
      <Section number="10.1">
        Den Nutzern ist es untersagt, Inhalte (z. B. durch Links oder Frames) auf dem Marktplatz einzustellen, die gegen
        gesetzliche Vorschriften, behördliche Anordnungen oder gegen die guten Sitten verstoßen. Ferner ist es ihnen
        untersagt, Inhalte einzustellen, die Rechte, insbesondere Urheber-, Design- oder Markenrechte Dritter verletzen.
      </Section>
      <Section number="10.2">
        Der Nutzer garantiert MilkScout und den übrigen Nutzern, dass die von ihm in Ausschreibungen oder Auktionen
        angebotenen Waren und Dienstleistungen, insbesondere Produktabbildungen, -beschreibungen oder –kennzeichnnungen,
        keine Urheberrechte, Marken, Designs, Patente, andere Schutzrechte, Geschäfts- oder Betriebsgeheimnisse
        verletzen.
      </Section>
      <Section number="10.3">
        MilkScout macht sich fremde Inhalte unter keinen Umständen zu Eigen. Auf dem Marktplatz veröffentlichte Inhalte
        von Nutzern stellen nicht die Meinung von MilkScout dar und werden grundsätzlich nicht von MilkScout auf ihre
        Rechtmäßigkeit, Richtigkeit und Vollständigkeit überprüft.
      </Section>
      <Section number="10.4">
        MilkScout behält sich vor, Nutzer, Ausschreibungen, Auktionen, Anfragen, Angebote, oder sonstige Inhalten zu
        sperren oder vom Marktplatz zu nehmen, wenn diese gegen diese Nutzungsbedingungen, gesetzliche Vorschriften,
        behördliche Anordnungen oder gegen die guten Sitten verstoßen, oder wenn begründeter Anlass zu der Sorge
        besteht, dass damit Rechte Dritter verletzt werden könnten.
      </Section>
      <Section number="10.5">
        Der Nutzer wird MilkScout von sämtlichen Ansprüchen freistellen, die Dritte gegen MilkScout wegen der Verletzung
        ihrer Rechte oder wegen Rechtsverstößen aufgrund der vom Nutzer eingestellten Daten und sonstigen Inhalte
        geltend machen. Der Nutzer übernimmt diesbezüglich auch die Kosten der Rechtsverteidigung von MilkScout
        einschließlich sämtlicher Gerichts- und Anwaltskosten.
      </Section>
      <Paragraph number={11}>Sonstige Pflichten des Nutzers</Paragraph>
      <Section number="11.1">
        <div>Der Nutzer ist verpflichtet,</div>
        <Section number="11.1.1">
          die erforderlichen Datensicherungsvorkehrungen während der gesamten Vertragslaufzeit einzurichten und
          aufrechtzuerhalten sowie sorgfältig und gewissenhaft mit den Logins und Passwörtern umzugehen;
        </Section>
        <Section number="11.1.2">
          in seinem Bereich eintretende technische Änderungen oder personelle Änderungen hinsichtlich der
          zugriffsberechtigten Mitarbeiter MilkScout umgehend mitzuteilen, wenn sie geeignet sind, die
          Leistungserbringung oder die Sicherheit des Marktplatzes zu beeinträchtigen;
        </Section>
        <Section number="11.1.3">
          bei der Aufklärung von Angriffen Dritter auf den Marktplatz mitzuwirken, soweit diese Mitwirkung durch den
          Nutzer erforderlich ist;
        </Section>
        <Section number="11.1.4">
          Geschäfte auf dem Marktplatz ausschließlich im Rahmen des kaufmännischen Geschäftsbetriebs zu gewerblichen
          Zwecken zu tätigen.
        </Section>
      </Section>
      <Section number="11.2">
        Nutzer dürfen keine von MilkScout oder anderen Nutzern generierten Inhalte blockieren, überschreiben oder
        modifizieren oder in sonstiger Weise störend in den Marktplatz eingreifen, insbesondere wenn hierdurch eine
        übermäßige Belastung der Marktplatz-Infrastruktur herbeigeführt werden könnte.
      </Section>
      <Section number="11.3">
        Der Nutzer verpflichtet sich, alle Maßnahmen zu unterlassen, welche die Funktionsweise des Marktplatzes
        gefährden oder stören, sowie nicht auf Daten zuzugreifen, zu deren Zugang er nicht berechtigt ist. Weiterhin
        muss er dafür Sorge tragen, dass seine über den Marktplatz übertragenen Informationen und eingestellten Daten
        nicht mit Viren, Würmern oder Trojanern behaftet sind. Der Nutzer verpflichtet sich, MilkScout alle Schäden zu
        ersetzen, die aus der von ihm zu vertretenden Nichtbeachtung dieser Pflichten entstehen und darüber hinaus
        MilkScout von allen Ansprüchen Dritter, einschließlich der Anwalts- und Gerichtskosten, freizustellen, die diese
        aufgrund der Nichtbeachtung dieser Pflichten durch den Nutzer gegen MilkScout geltend machen.
      </Section>
      <Paragraph number={12}>Haftung von MilkScout</Paragraph>
      <Section number="12.1">
        MilkScout haftet nach den gesetzlichen Vorschriften für Vorsatz und grobe Fahrlässigkeit von MilkScout, ihren
        gesetzlichen Vertretern, leitenden Angestellten oder sonstigen Erfüllungsgehilfen. Gleiches gilt bei der
        Übernahme von Garantien oder einer sonstigen verschuldensunabhängigen Haftung sowie bei Ansprüchen nach dem
        Produkthaftungsgesetz oder bei einer schuldhaften Verletzung des Lebens, des Körpers oder der Gesundheit.
      </Section>
      <Section number="12.2">
        MilkScout haftet dem Grunde nach für durch MilkScout, ihre Vertreter, leitenden Angestellten und einfachen
        Erfüllungsgehilfen verursachte einfach fahrlässige Verletzungen wesentlicher Vertragspflichten, also solcher
        Pflichten, auf deren Erfüllung der Nutzer zur ordnungsgemäßen Durchführung des Vertrages regelmäßig vertraut und
        vertrauen darf, in diesem Fall aber der Höhe nach begrenzt auf den typischerweise entstehenden, vorhersehbaren
        Schaden.
      </Section>
      <Section number="12.3">Eineweitere Haftung von MilkScout ist ausgeschlossen.</Section>
      <Section number="12.4">
        Soweit die Haftung von MilkScout ausgeschlossen oder beschränkt ist, gilt dies auch zugunsten der persönlichen
        Haftung ihrer gesetzlichen Vertreter, leitenden Angestellten und einfachen Erfüllungsgehilfen.
      </Section>
      <Section number="12.5">
        Die Haftung erstreckt sich nicht auf Beeinträchtigungen des vertragsgemäßen Gebrauchs der von MilkScout auf dem
        Marktplatz erbrachten Leistungen, die durch eine unsachgemäße oder fehlerhafte Inanspruchnahme durch den Nutzer
        verursacht worden sind.
      </Section>
      <Paragraph number={13}>Laufzeit des Nutzungsvertrages; Kündigung</Paragraph>
      <Section number="13.1">
        Der diesen Nutzungsbedingungen zugrundeliegende Vertrag wird auf unbestimmte Zeit abgeschlossen. Er beginnt mit
        der Registrierung des Nutzers durch MilkScout gemäß § 3.
      </Section>
      <Section number="13.2">
        Der Vertrag kann von MilkScout und dem Nutzer jeweils mit einer Frist von einem Monat zum Monatsende gekündigt
        werden.
      </Section>
      <Section number="13.3">
        <div>
          Jede Partei hat das Recht, den Vertrag aus wichtigem Grund ohne Einhaltung einer Kündigungsfrist zu kündigen.
          Ein wichtiger Grund ist für MilkScout insbesondere:
        </div>
        <Section number="13.3.1">
          der Verstoß eines Nutzers gegen die Bestimmungen dieser Nutzungsbedingungen, der auch nach Fristsetzung nicht
          beseitigt wird;
        </Section>
        <Section number="13.3.2">
          der Verzug des Nutzers mit der Zahlungspflicht gemäß der vom Nutzer gemäß § 9 zu leistenden Zahlung um mehr
          als sechs Wochen.
        </Section>
        <Section number="13.3.3">
          andauernde Betriebsstörungen infolge von höherer Gewalt, die außerhalb der Kontrolle von MilkScout liegen, wie
          z. B. Naturkatastrophen, Brand, unverschuldeter Zusammenbruch von Leitungsnetzen.
        </Section>
      </Section>
      <Section number="13.4">
        Eine Kündigung hat keine Auswirkungen auf die Wirksamkeit von bereits auf dem Marktplatz zustande gekommenen
        Verträgen und die daraus resultierenden Vergütungsansprüche von MilkScout.
      </Section>
      <Paragraph number={14}>Schlussbestimmungen</Paragraph>
      <Section number="14.1">
        Eine teilweise oder vollständige Übertragung der Rechte des Nutzers aus dem Vertrag mit MilkScout auf Dritte ist
        ausgeschlossen.
      </Section>
      <Section number="14.2">
        Sämtliche Erklärungen, die im Rahmen des mit MilkScout abgeschlossenen Nutzungsvertrags übermittelt werden,
        müssen in Schriftform oder Textform (z. B. per E-Mail) erfolgen.
      </Section>
      <Section number="14.3">
        Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.
      </Section>
      <Section number="14.4">
        Gerichtsstand für alle Rechtsstreitigkeiten ist Landshut. MilkScout ist daneben berechtigt, auch am allgemeinen
        Gerichtsstand des Nutzers zu klagen.
      </Section>
      <Section number="14.5">
        Sollten einzelne Bestimmungen dieser Nutzungsbedingungen ganz oder teilweise nichtig oder unwirksam sein oder
        werden, so wird dadurch die Wirksamkeit der übrigen Bestimmungen nicht berührt. An die Stelle von nicht
        einbezogenen oder unwirksamen Bestimmungen dieser Nutzungsbedingungen tritt das Gesetzesrecht. Sofern solches
        Gesetzesrecht im jeweiligen Fall nicht zur Verfügung steht (Regelungslücke) oder zu einem untragbaren Ergebnis
        führen würde, werden MilkScout und der Nutzer in Verhandlungen darüber eintreten, anstelle der nicht
        einbezogenen oder unwirksamen Bestimmung eine wirksame Regelung zu treffen, die ihr wirtschaftlich möglichst
        nahekommt.
      </Section>
    </>
  );
};
