import { Backdrop, BackdropProps as MatBackdropProps, Modal as MaterialModal } from '@material-ui/core';
import React, { CSSProperties, PropsWithChildren } from 'react';
import { noop } from '@milkscout/react';

export interface IPropsModal {
  open: boolean;
  style?: CSSProperties;
  BackdropProps?: Partial<MatBackdropProps>;
  onClose?: () => void;
}

export const Modal = ({ children, BackdropProps, open, onClose = noop, style }: PropsWithChildren<IPropsModal>) => (
  <MaterialModal
    open={open}
    closeAfterTransition
    onClick={(evt) => evt.stopPropagation()}
    onClose={(evt: any) => {
      evt.stopPropagation();
      onClose();
    }}
    style={style}
    BackdropComponent={Backdrop}
    keepMounted={process.env.NODE_ENV !== 'production'}
    BackdropProps={{
      timeout: 500,
      ...BackdropProps,
    }}
  >
    <>{children}</>
  </MaterialModal>
);
