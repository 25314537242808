import React, { PropsWithChildren } from 'react';
import { Container, Grid } from '@material-ui/core';
import { Card } from '../Card';
import { PaperPlane } from '../../../../components/images/PaperPlane';
import { LightBulb } from '../../../../components/images/LightBulb';
import { Globe } from '../../../../components/images/Globe';
import { DesktopMonitor } from '../../../../components/images/DesktopMonitor';
import { getNsTrans } from '../../../../translation';
import { FadeInLeftAnimation } from '../../../../components/animation/FadeInLeftAnimation';
import { InViewAnimation } from '../../../../components/animation/InViewAnimation';

const Animation = ({ children }: PropsWithChildren<unknown>) => (
  <InViewAnimation repeat animation={<FadeInLeftAnimation duration={2.5} />}>
    {children}
  </InViewAnimation>
);

const Trans = getNsTrans('info');
export interface InfoProps {
  className?: string;
}

export const Info = ({ className }: InfoProps) => (
  <div>
    <Container maxWidth="lg" className={className}>
      <Grid container>
        <Grid item xs={12} sm={6} lg={3}>
          <Animation>
            <Card icon={<PaperPlane />} title={<Trans>Independent</Trans>}>
              <Trans>
                MilkScout is an independent company and provides online services for the entire dairy industry.
              </Trans>
            </Card>
          </Animation>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Animation>
            <Card icon={<LightBulb />} title={<Trans>Innovative</Trans>}>
              <Trans>Where traditional structures reach their limits, we find new solutions.</Trans>
            </Card>
          </Animation>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Animation>
            <Card icon={<Globe />} title={<Trans>International</Trans>}>
              <Trans>Our headquarter is in Landshut, our market is Europe.</Trans>
            </Card>
          </Animation>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Animation>
            <Card icon={<DesktopMonitor />} title={<Trans>Digital</Trans>}>
              <Trans>Through our know-how, we can offer new opportunities to all market participants.</Trans>
            </Card>
          </Animation>
        </Grid>
      </Grid>
    </Container>
  </div>
);
