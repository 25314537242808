import React from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
  Toolbar,
} from '@material-ui/core';
import { Hide, useMountedState } from '@milkscout/react';
import { useHistory } from 'react-router-dom';
import { MenuIcon } from '@material-ui-extra/icons/MenuIcon';
import milkscoutLogo from '../../../../../assets/img/logo-big.svg';
import { LoginButton, RegisterButton } from './MenuButtons';
import { LanguageMenu } from '../Menu';
import { ListItemIcon as ListItemIconIcon } from '../../../../../components/images/ListItemIcon';
import { NAVIGATION_ITEMS } from '../Menu/NavigationItems';

const getClasses = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    height: 54,
    margin: 'auto',
    cursor: 'pointer',
    alignSelf: 'center',
  },
  button: {
    color: 'black',
    alignSelf: 'center',
    backgroundColor: '#f8f9fa',
  },
  toolbar: {
    minHeight: 110,
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    alignSelf: 'flex-end',
  },
  drawerButton: {
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 4,
  },
  listItemIcon: { width: 32, height: 32 },
  spacer: { flex: 1 },
  languageWrapper: { textAlign: 'center', justifyContent: 'center', margin: 'auto' },
}));

export interface DesktopToolbarProps {
  isMobile: boolean;
}

export const ToolbarMobile = ({ isMobile }: DesktopToolbarProps) => {
  const classes = getClasses();
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = useMountedState<boolean>(false);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <Hide show={isMobile}>
      <Toolbar className={classes.toolbar}>
        <img src={milkscoutLogo} className={classes.logo} alt="MilkScout logo" onClick={() => history.push('/')} />
        <IconButton
          edge="start"
          className={classes.button}
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon style={{ fontSize: 40 }} />
        </IconButton>
        <SwipeableDrawer
          anchor="right"
          open={isMobile && drawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <div style={{ margin: 15 }}>
            <img src={milkscoutLogo} style={{ minWidth: 200 }} alt="MilkScout logo" />
          </div>
          <RegisterButton className={classes.drawerButton} />
          <LoginButton className={classes.drawerButton} />
          <List>
            {NAVIGATION_ITEMS.map(({ label, route }) => (
              <ListItem button onClick={() => history.push(route)} key={`menu-desktop-${route}`}>
                <ListItemIcon>
                  <ListItemIconIcon className={classes.listItemIcon} />
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </List>
          <div className={classes.spacer} />
          <div className={classes.languageWrapper}>
            <LanguageMenu />
          </div>
        </SwipeableDrawer>
      </Toolbar>
    </Hide>
  );
};
