import React, { CSSProperties } from 'react';

export interface ListItemProps {
  style?: CSSProperties;
  className?: string;
}

export const ListItemIcon = ({ style, className }: ListItemProps) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    className={className}
    viewBox="0 0 1024 1896.0833"
  >
    <path d="M595 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393L23 567q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23zm384 0q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z" />
  </svg>
);
