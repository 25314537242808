import { createTheme, Theme, ThemeOptions } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core/styles/createTypography';
import { ColorPalette } from './ColorPalette';

export interface CustomTypographyTheme extends Theme {
  typography: Typography & {
    fontFamilyLinks?: Array<string>;
  };
}

export const muiTheme = createTheme();

export const ciThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: ColorPalette.PRIMARY,
    },
    secondary: {
      main: ColorPalette.SECONDARY,
    },
    background: {
      default: ColorPalette.BACKGROUND,
    },
    error: {
      main: ColorPalette.ERROR,
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    overline: {
      textTransform: 'none',
    },
    fontFamily: ['Nunito', 'sans-serif'].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: { color: ColorPalette.PRIMARY },
        '@font-face': [
          {
            fontFamily: "'Nunito', sans-serif",
          },
        ],
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: muiTheme.palette.action.hover,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: muiTheme.spacing(1.5, 3),
      },
    },
    MuiDialogContent: {
      dividers: {
        padding: muiTheme.spacing(4, 3, 1, 3),
      },
    },
    MuiDialogActions: {
      root: {
        padding: muiTheme.spacing(3),
      },
    },
    MuiFormHelperText: {
      root: {
        marginTop: muiTheme.spacing(0.5),
        marginBottom: muiTheme.spacing(1),
        lineHeight: muiTheme.typography.caption.fontSize,
      },
    },
  },
};
