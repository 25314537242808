import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { HorizontalOverlap } from '../../../components/HorizontalOverlap';
import shakingHandsImageSrc from '../../../assets/img/shaking-hands.png';
import { ListItemIcon } from '../../../components/images/ListItemIcon';
import { ListItem } from './ListItem';
import { getNsTrans } from '../../../translation';

const Trans = getNsTrans('main-page');
const getClasses = makeStyles(({ palette }: Theme) => ({
  icon: {
    width: 60,
    height: 60,
    fill: palette.primary.main,
  },
  root: {
    color: '#fff',
    fill: '#fff',
    backgroundColor: palette.primary.main,
  },
  headline: {
    fontSize: 36,
    textAlign: 'center',
    marginBottom: 20,
    textTransform: 'uppercase',
  },
  button: {
    color: '#fff',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    '&:hover': {
      color: palette.primary.main,
      fill: palette.primary.main,
      backgroundColor: '#fff',
    },
  },
}));

export interface DigitalSpotMarketProps {
  className?: string;
}

export const DigitalSpotMarket = ({ className }: DigitalSpotMarketProps) => {
  const classes = getClasses();
  return (
    <div className={className}>
      <HorizontalOverlap imageUrl={shakingHandsImageSrc}>
        <div className={classes.root}>
          <div className={classes.headline}>
            <Trans>Digital spot market</Trans>
          </div>
          <div>
            <ListItem>
              <Trans>Better market and price transparency</Trans>
            </ListItem>
            <ListItem>
              <Trans>Defined search queries are checking the market 24/7 for suitable offers</Trans>
            </ListItem>
            <ListItem>
              <Trans>Possibility of data connections to ERP and planning systems</Trans>
            </ListItem>
            <ListItem>
              <Trans>Online raw material reconciliation (quantity/price)</Trans>
            </ListItem>
            <ListItem>
              <Trans>Organization of transport orders</Trans>
            </ListItem>
          </div>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<ListItemIcon style={{ height: 24 }} />}
          >
            <Trans>Learn more</Trans>
          </Button>
        </div>
      </HorizontalOverlap>
    </div>
  );
};
