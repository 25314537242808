import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { joinClasses, openUrlInNewTab } from '@milkscout/react';
import { getNsTrans } from '../../../../../translation';

const getClasses = makeStyles((theme) => ({
  buttons: {
    alignSelf: 'center',
  },
  button: {
    fontWeight: 'bold',
    fontSize: 12,
    color: '#fff',
    textTransform: 'uppercase',
  },
  loginButton: {
    marginLeft: theme.spacing(1),
  },
}));

const Trans = getNsTrans('menu');

export interface RegisterButtonProps {
  className?: string;
}

export const RegisterButton = ({ className }: RegisterButtonProps) => {
  const classes = getClasses();
  return (
    <Button
      variant="contained"
      color="primary"
      size="large"
      className={joinClasses(classes.button, className)}
      onClick={() => openUrlInNewTab(process.env.REACT_APP_MILKSCOUT_REGISTER_URL as string)}
    >
      <Trans>Register for free</Trans>
    </Button>
  );
};
export interface LoginButtonProps {
  className?: string;
}

export const LoginButton = ({ className }: LoginButtonProps) => {
  const classes = getClasses();
  return (
    <Button
      variant="contained"
      color="secondary"
      size="large"
      className={joinClasses(classes.button, className)}
      onClick={() => openUrlInNewTab(process.env.REACT_APP_MILKSCOUT_LOGIN_URL as string)}
    >
      <Trans>Login</Trans>
    </Button>
  );
};

export const MenuButtons = () => {
  const classes = getClasses();
  return (
    <div className={classes.buttons}>
      <RegisterButton />
      <LoginButton className={classes.loginButton} />
    </div>
  );
};
