import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getNsTrans } from '../../../../translation';
import { TRANSPORT_PARTNERS, TransportType } from '../../../../assets/img/partners/resources';
import { ExternalLink } from '../../../../components/ExternalLink';

const Trans = getNsTrans('info-partner');
const getClasses = makeStyles(({ palette }: Theme) => ({
  header: {
    fontSize: 40,
    color: palette.primary.main,
    textAlign: 'center',
    marginBottom: 15,
  },
  logo: {
    filter: 'grayscale(1)',
    '&:hover': {
      filter: 'grayscale(0)',
    },
  },
}));
const PARTNER_LINK_MAP: Record<keyof TransportType, string | undefined> = {
  CLEANING_ROECK: 'https://www.roeck-tankreinigung.de/',
  TRANSPORT_AWS: 'https://www.asw-transport.de/',
  TRANSPORT_BERKMANN: 'https://berkmann-transporte.de/',
  TRANSPORT_BIEL_TRANSPORTE: 'https://www.biel-transporte.de/',
  TRANSPORT_DOBELMANN: 'https://dobelmann-transporte.de/',
  TRANSPORT_DOKTOR_CARGO: 'https://www.doktor-cargo.com/',
  TRANSPORT_FUCHS_TRANSPORTE: 'http://fuchs-transporte.net/',
  TRANSPORT_GROISS: 'https://www.groiss-trans.at/',
  TRANSPORT_JUNG: 'https://fluessig-transporte-jung.de/',
  TRANSPORT_KRALOWETZ: 'http://www.kralowetz.at/',
  TRANSPORT_LETTEL: 'https://www.lettl-tank.de/',
  TRANSPORT_LIQUIDMASTER: 'https://www.liquidmaster.de/',
  TRANSPORT_PIENDL: 'https://www.transporte-piendl.de/',
  TRANSPORT_ROECK: 'https://roeck-milchtransporte.de/',
  TRANSPORT_SCHALBECK_KALB: undefined,
  TRANSPORT_STREDELE: 'https://milchtransporte.eu/',
};

export const Partners = () => {
  const classes = getClasses();
  return (
    <div>
      <div className={classes.header}>
        <Trans>Our partners</Trans>
        <div>
          {Object.entries(TRANSPORT_PARTNERS).map(([key, value]) => {
            const type = key as keyof TransportType;
            const url = PARTNER_LINK_MAP[type];
            const isUrl = !!url;
            return (
              <ExternalLink
                key={key}
                href={url}
                className={classes.logo}
                style={{ cursor: isUrl ? 'pointer' : 'inherit' }}
              >
                <img src={value} width={150} alt={`logo ${key}`} />
              </ExternalLink>
            );
          })}
        </div>
      </div>
    </div>
  );
};
