import React, { CSSProperties, ReactElement, useCallback, useEffect, useRef } from 'react';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { joinClasses, useMountedState } from '@milkscout/react';

const getClasses = makeStyles({
  image: {
    maxHeight: 400,
    margin: 'auto',
    zIndex: 1,
  },
  desktopContent: {
    padding: 90,
    paddingLeft: 100,
    paddingRight: 100,
  },
  mobileContent: {
    padding: 30,
  },
  tabletContent: {
    padding: 30,
    paddingLeft: 45,
    paddingRight: 45,
  },
});

export interface HorizontalOverlapProps {
  imageUrl: string;
  children: ReactElement;
}

export const HorizontalOverlap = ({ children, imageUrl }: HorizontalOverlapProps) => {
  const contentItemRef = useRef<any>(null);
  const imageItemRef = useRef<any>(null);
  const classes = getClasses();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const [imageStyle, setImageStyle] = useMountedState<CSSProperties>({});
  const [wrapperStyle, setWrapperStyle] = useMountedState<CSSProperties>({});
  const onResize = useCallback(() => {
    const { current } = contentItemRef;
    const { current: currentImage } = imageItemRef;
    if (current && currentImage) {
      const { height } = (current as HTMLElement).getBoundingClientRect();
      const { width } = (currentImage as HTMLElement).getBoundingClientRect();
      if (desktop) {
        setImageStyle({ maxWidth: width, marginRight: -70 });
        setWrapperStyle({ height });
      } else {
        setImageStyle({ maxWidth: '80%', marginBottom: -15 });
        setWrapperStyle({});
      }
    }
  }, [desktop, setImageStyle, setWrapperStyle]);
  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);
  return (
    <Grid container>
      <Grid item xs={12} md={4} lg={6} ref={imageItemRef}>
        <div style={{ display: 'flex', flexDirection: 'column', ...wrapperStyle }}>
          <img src={imageUrl} alt="overlap" className={classes.image} style={imageStyle} />
        </div>
      </Grid>
      <Grid item xs={12} md={8} lg={6} ref={contentItemRef} style={{}}>
        {React.cloneElement(children, {
          className: joinClasses(
            {
              [classes.desktopContent]: desktop,
              [classes.mobileContent]: mobile,
              [classes.tabletContent]: !mobile && !desktop,
            },
            children.props.className,
          ),
        })}
      </Grid>
    </Grid>
  );
};
