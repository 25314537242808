import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { ngFetch } from 'ng-fetch';
import { parseLanguage } from './functions';

export interface ITranslationValues {
  [langulage: string]: {
    [namespace: string]: {
      [key: string]: string; // translation value
    };
  };
}

export const initI18n = (translations: ITranslationValues) => {
  i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      fallbackLng: parseLanguage,
      debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
      nsSeparator: false,
      keySeparator: false,
      resources: translations,
      interpolation: {
        // not needed for react as it escapes by default
        escapeValue: false,
      },
      saveMissing: true,
      missingKeyHandler: async (lngs: string[], ns: string, key: string) => {
        if (process.env.NODE_ENV !== 'production') {
          try {
            await ngFetch(`http://localhost:12345/missing/${ns}`, {
              method: 'PUT',
              queryParameter: [{ name: 'key', value: key }],
            });
          } catch (_) {
            // do nothing
          }
        }
      },
    });

  return i18n;
};
