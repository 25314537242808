import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Slide } from 'react-slideshow-image';
import { getNsTrans } from '../../../translation';
import 'react-slideshow-image/dist/styles.css';
import { ListItem } from './ListItem';

const getClasses = makeStyles(({ palette }: Theme) => ({
  root: {
    marginTop: 50,
    marginBottom: 50,
  },
  slideContainer: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  header: {
    textAlign: 'center',
    fontSize: 36,
    textTransform: 'uppercase',
    color: palette.primary.main,
  },
  category: {
    display: 'flex',
    margin: 30,
    color: palette.primary.main,
    '& > div': {
      width: '25%',
    },
  },
  categoryHeader: {
    fontSize: 20,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: 15,
  },
}));

const Trans = getNsTrans('how-it-work');
export const HowItWork = () => {
  const classes = getClasses();

  return (
    <div className={classes.root}>
      <div className="slide-container">
        <Slide indicators>
          <div className="each-slide">
            <div className={classes.slideContainer}>
              <div className={classes.header}>
                <Trans>How can i register with MilkScout?</Trans>
              </div>
              <div className={classes.category}>
                <div>
                  <div className={classes.categoryHeader}>
                    <Trans>Registration</Trans>
                  </div>
                  <ListItem>
                    <Trans>Free of charge</Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>Specification of e-mail and user name</Trans>
                  </ListItem>
                </div>
                <div>
                  <div className={classes.categoryHeader}>
                    <Trans>Verification</Trans>
                  </div>
                  <ListItem>
                    <Trans>Confirmation of the e-mail address</Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>Company details</Trans>
                  </ListItem>
                </div>
                <div>
                  <div className={classes.categoryHeader}>
                    <Trans>Testing</Trans>
                  </div>
                  <ListItem>
                    <Trans>Review the data by the support</Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>Credit check</Trans>
                  </ListItem>
                </div>
                <div>
                  <div className={classes.categoryHeader}>
                    <Trans>Release</Trans>
                  </div>
                  <ListItem>
                    <Trans>The account is activated</Trans>
                  </ListItem>
                </div>
              </div>
            </div>
          </div>
          <div className="each-slide">
            <div className={classes.slideContainer}>
              <div className={classes.header}>
                <Trans>How can i buy from MilkScout?</Trans>
              </div>
              <div className={classes.category}>
                <div>
                  <div className={classes.categoryHeader}>
                    <Trans>Observe marketplace</Trans>
                  </div>
                  <ListItem>
                    <Trans>Filter options</Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>Sort by price and distance</Trans>
                  </ListItem>
                </div>
                <div>
                  <div className={classes.categoryHeader}>
                    <Trans>Select offer</Trans>
                  </div>
                  <ListItem>
                    <Trans>Confirm GTC</Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>Purchase with binding effect</Trans>
                  </ListItem>
                </div>
                <div>
                  <div className={classes.categoryHeader}>
                    <Trans>Confirmation by seller</Trans>
                  </div>
                  <ListItem>
                    <Trans>Seller confirms the offer as soon as possible</Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>Confirmation period of 8 hours</Trans>
                  </ListItem>
                </div>
                <div>
                  <div className={classes.categoryHeader}>
                    <Trans>Entering into contact</Trans>
                  </div>
                  <ListItem>
                    <Trans>Possibility to generate a transport request</Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>MilkScout receives a small commission from the buyer</Trans>
                  </ListItem>
                </div>
              </div>
            </div>
          </div>
          <div className="each-slide">
            <div className={classes.slideContainer}>
              <div className={classes.header}>
                <Trans>How can i sell at MilkScout?</Trans>
              </div>
              <div className={classes.category}>
                <div>
                  <div className={classes.categoryHeader}>
                    <Trans>Placing offer</Trans>
                  </div>
                  <ListItem>
                    <Trans>Free of charge</Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>More than 10 raw materials</Trans>
                  </ListItem>
                </div>
                <div>
                  <div className={classes.categoryHeader}>
                    <Trans>Advertisement in the marketplace</Trans>
                  </div>
                  <ListItem>
                    <Trans>Anonymous</Trans>
                  </ListItem>
                  <ListItem>
                    <Trans>Specification of quality characteristics, collection date, offer duration</Trans>
                  </ListItem>
                </div>
                <div>
                  <div className={classes.categoryHeader}>
                    <Trans>Purchase request by buyer</Trans>
                  </div>
                  <ListItem>
                    <Trans>Confirmation of purchase by seller</Trans>
                  </ListItem>
                </div>
                <div>
                  <div className={classes.categoryHeader}>
                    <Trans>Entering into contact</Trans>
                  </div>
                  <ListItem>
                    <Trans>Transmission of the seller's data to the buyer</Trans>
                  </ListItem>
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </div>
    </div>
  );
};
