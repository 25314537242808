import { dispatchCustomEvent, useMountedState } from '@milkscout/react';
import { useEffect } from 'react';
import { ngFetch } from 'ng-fetch';
import { BUILD_DATE } from './info';

const UPDATE_EVENT_NAME = 'milkscoutUiUpdate';

const isUpdate = async (): Promise<boolean> => {
  const rawResponse = await ngFetch('/info.json', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
    },
  });

  const responseJSON = JSON.parse(rawResponse.response.toString());
  const { date: buildDate } = responseJSON;
  return BUILD_DATE !== buildDate;
};

export const register = (intervalInSeconds: number, eventName = UPDATE_EVENT_NAME) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.info(`%cVersion reload registered interval ${intervalInSeconds} seconds`, 'font-weight:bold');
  }
  const triggerUpdateCheck = async () => {
    try {
      const check = await isUpdate();
      if (check) {
        dispatchCustomEvent(eventName);
      }
    } finally {
      window.setTimeout(triggerUpdateCheck, intervalInSeconds * 1000);
    }
  };
  triggerUpdateCheck();
};

export const useIsUpdate = (eventName = UPDATE_EVENT_NAME) => {
  const [update, setUpdate] = useMountedState(false);

  useEffect(() => {
    const eventListener = () => setUpdate(true);
    window.addEventListener(eventName, eventListener);
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  });
  return update;
};
