import React, { PropsWithChildren, ReactElement } from 'react';
import {
  AccordionDetails,
  AccordionSummary,
  Typography,
  Accordion as AccordionMat,
  makeStyles,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { useMountedState } from '@milkscout/react';
import { AccordionIcon } from './AccordionIcon';

const getClasses = makeStyles(({ palette }: Theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    marginBottom: 10,
    marginRight: '25px !important',
  },
  question: {
    color: palette.primary.main,
    fontWeight: 600,
    textTransform: 'uppercase',
    paddingBottom: 13,
    paddingTop: 13,
  },
  answer: {
    paddingTop: 0,
  },
  expanded: {
    transform: 'unset !important',
    marginRight: -50,
  },
}));
export interface AccordionProps {
  question: ReactElement;
  foreExpanded?: boolean;
}

export const Accordion = ({ question, children, foreExpanded = false }: PropsWithChildren<AccordionProps>) => {
  const classes = getClasses();
  const [expanded, setExpanded] = useMountedState<boolean>(foreExpanded);
  return (
    <AccordionMat
      classes={{ root: classes.root }}
      expanded={expanded}
      onChange={(_, state) => setExpanded(state)}
      TransitionProps={{ timeout: 0 }}
    >
      <AccordionSummary
        IconButtonProps={{ classes: { root: classes.expanded } }}
        expandIcon={<AccordionIcon expanded={expanded} />}
      >
        <Typography className={classes.question}>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.answer}>
        <Typography>{children}</Typography>
      </AccordionDetails>
    </AccordionMat>
  );
};
