export const joinUrl = (rawBasePath: string, rawSuffix: string) => {
  let suffix = rawSuffix;
  let basePath = rawBasePath;
  if (rawSuffix.startsWith('/')) {
    suffix = rawSuffix.substring(1);
  }
  if (rawBasePath.endsWith('/')) {
    basePath = rawBasePath.substring(0, rawBasePath.length - 1);
  }
  return `${basePath}/${suffix}`;
};
