import React from 'react';
import {Container, makeStyles} from '@material-ui/core';
import {PageLayout} from '../layout';
import {Banner} from './components/Banner';
import {Info} from './components/Info';
import {DigitalSpotMarket} from './components/DigitalSpotMarket';
import {Pricing} from './components/Pricing';
import {FeatureInfo} from './components/FeatureInfo';
import {Register} from './components/Register';
import {MilkAlarm} from './components/MilkAlarm';
import {Faq} from './components/Faq';
import {Partners} from './components/Partner/Partners';
import {Haulers} from './components/Haulers';
import {InfoAboutUs} from './components/InfoAboutUs';
import {InfoNumbers} from './components/InfoNumbers';
import {ParallaxImage} from './components/ParallaxImage';
import {HowItWork} from './components/HowItWork';

const getClasses = makeStyles({
  container: { paddingTop: 20, paddingBottom: 20 },
});

export const MainPage = () => {
  const classes = getClasses();
  return (
    <PageLayout>
      <Banner />
      <Container maxWidth="lg">
        <Info className={classes.container} />
        <DigitalSpotMarket className={classes.container} />
      </Container>
      <Container maxWidth="lg">
        <InfoNumbers />
      </Container>
      <ParallaxImage />
      <Container maxWidth="lg">
        <HowItWork />
      </Container>
      <InfoAboutUs />
      <Container maxWidth="lg">
        <Haulers />
        <Partners />
      </Container>
      <Faq />
      <Container maxWidth="lg">
        <MilkAlarm />
        <FeatureInfo />
        <Pricing className={classes.container} />
        <Register />
      </Container>
    </PageLayout>
  );
};
