import React, { useEffect, useRef } from 'react';

export interface CounterProps extends Omit<React.HTMLAttributes<HTMLSpanElement>, 'children'> {
  durationInSeconds: number;
  end: number;
}

export const Counter = ({ durationInSeconds, end, ...props }: CounterProps) => {
  const ref = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    const durationInMs = durationInSeconds * 1000;
    const startTime = Date.now();
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const { current } = ref;
      if (current) {
        const restTimeInSeconds = Math.max(0, (currentTime - startTime) / 1000);
        const currentPercentage = (100 / durationInSeconds) * restTimeInSeconds;
        const counter = (end / 100) * currentPercentage;
        current.innerText = Number(counter).toFixed(0);
      }
    }, 0);

    const timeout = setTimeout(() => clearInterval(interval), durationInMs);
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [durationInSeconds, end]);

  return <span ref={ref} {...props} />;
};
