import '@fontsource/nunito';
import '@fontsource/nunito/500.css';
import '@fontsource/nunito/600.css';
import '@fontsource/nunito/700.css';
import '@fontsource/open-sans';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';

import React, { memo, PropsWithChildren } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeOptions, ThemeProvider, createTheme } from '@material-ui/core/styles';
import { ciThemeOptions } from './ciTheme';

export const CIThemeProvider = ({ children, ciThemeOption = ciThemeOptions }: PropsWithChildren<IThemeProps>) => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.groupCollapsed('Theme options used');
    // eslint-disable-next-line no-console
    console.info(ciThemeOptions);
    // eslint-disable-next-line no-console
    console.groupEnd();
  }

  return (
    <ThemeProvider theme={createTheme(ciThemeOption)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export interface IThemeProps {
  ciThemeOption?: ThemeOptions;
}

export default memo(CIThemeProvider);
