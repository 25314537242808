import React from 'react';
import { Grid, List } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getNsTrans } from '../../../translation';
import { ListItem } from './ListItem';

const getClasses = makeStyles(({ palette }: Theme) => ({
  header: {
    color: palette.primary.main,
    fontSize: 36,
  },
}));
const Trans = getNsTrans('info-milk-alarm');

export const MilkAlarm = () => {
  const classes = getClasses();
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.header}>
          <Trans>MilkAlarm in 3 steps</Trans>
        </div>
      </Grid>
      <Grid item md={6} xs={12} />
      <Grid item md={6} xs={12}>
        <List>
          <ListItem>
            <Trans>Select raw material</Trans>
          </ListItem>
          <ListItem>
            <Trans>Enter maximum price and required quality characteristics</Trans>
          </ListItem>
          <ListItem>
            <Trans>Receive notification</Trans>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};
