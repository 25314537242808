import React, { PropsWithChildren } from 'react';

export interface SectionProps {
  number: string;
}

export const Section = ({ children, number }: PropsWithChildren<SectionProps>) => (
  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 25 }}>
    <div>{number}</div>
    <div style={{ flex: 1, paddingLeft: 12, textAlign: 'justify' }}>{children}</div>
  </div>
);
