import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Language, parseLanguage } from '../../translation';
import { ContentEnglish } from './ContentEnglish';
import { ContentGerman } from './ContentGerman';
import { InfoPageLayout } from '../layout/InfoPageLayout';

const LANGUAGE_CONTENT_MAP: Record<Language, ReactNode> = {
  'de-DE': <ContentGerman />,
  'en-US': <ContentEnglish />,
  'pl-PL': <ContentEnglish />,
};

export const DataPrivacyPage = () => {
  const { i18n } = useTranslation();
  const content = LANGUAGE_CONTENT_MAP[parseLanguage(i18n.language)];

  return <InfoPageLayout>{content}</InfoPageLayout>;
};
