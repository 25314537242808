import React, { Suspense, useLayoutEffect } from 'react';
import { NotProductionValues } from '@milkscout/react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { MainPage } from './page/main/MainPage';
import {
  DATA_PRIVACY_ROUTE,
  FAQ_ROUTE,
  HAULER_ROUTE,
  IMPRINT_ROUTE,
  MAIN_ROUTE,
  TERMS_OF_USE_ROUTE,
} from './page/routes';
import { HaulerPage } from './page/hauler/HaulerPage';
import { FaqPage } from './page/faq/FaqPage';
import { ImprintPage } from './info-page/imprint/ImprintPage';
import { DataPrivacyPage } from './info-page/data-privacy/DataPrivacyPage';
import { TermsOfUsePage } from './info-page/terms-of-use/TermsOfUsePage';
import { getNsTrans } from './translation';

const Trans = getNsTrans('suspense');
const SuspenseFallback = <Trans>Page loading ...</Trans>;
export const Routes = () => {
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Suspense fallback={SuspenseFallback}>
      <NotProductionValues nodes={[SuspenseFallback]} />
      <Switch>
        <Route exact path={MAIN_ROUTE} component={MainPage} />
        <Route exact path={HAULER_ROUTE} component={HaulerPage} />
        <Route exact path={FAQ_ROUTE} component={FaqPage} />
        <Route exact path={IMPRINT_ROUTE} component={ImprintPage} />
        <Route exact path={DATA_PRIVACY_ROUTE} component={DataPrivacyPage} />
        <Route exact path={TERMS_OF_USE_ROUTE} component={TermsOfUsePage} />
        <Route exact path="*">
          <Redirect to={MAIN_ROUTE} />
        </Route>
      </Switch>
    </Suspense>
  );
};
