/* eslint-disable react/jsx-one-expression-per-line,react/no-unescaped-entities */
import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paragraph } from './components/Paragraph';
import { Section } from './components/Section';
import { GENERAL_TERMS_OF_USE_FILE_PATH } from '../../assets/public.references';
import { DATA_PRIVACY_ROUTE } from '../../page/routes';
import { Accordion } from './components/Accordion';
import { Divider } from './components/Divider';
import { joinUrl } from './functions/joinUrl';

const getClasses = makeStyles(({ palette }: Theme) => ({
  colored: {
    color: palette.primary.main,
  },
}));
export const ContentEnglish = () => {
  const classes = getClasses();
  return (
    <>
      <h1 className={classes.colored}>
        General Terms of Use
        <br />
        of MilkScout GmbH{' '}
        <a href={GENERAL_TERMS_OF_USE_FILE_PATH} target="_blank" rel="noopener noreferrer">
          (original in PDF)
        </a>
      </h1>
      <Paragraph number={1}>Marketplace; exclusive validity of the terms of use</Paragraph>
      <Section number="1.1">
        MilkScout GmbH, Kiem-Pauli-Straße 8, 84036 Landshut, Germany (hereinafter "MilkScout"), offers entrepreneurs
        within the meaning of § 14 of the German Civil Code and legal entities of public law (hereinafter "Users") the
        option of using a business-to-business trading system on the online marketplace operated by MilkScout
        (hereinafter "Marketplace") in accordance with the provisions of these Terms of Use.
      </Section>
      <Section number="1.2">
        These Terms of Use exclusively contain the terms and conditions applicable between MilkScout and the User for
        the services offered by MilkScout within the framework of this agreement. Any provisions deviating from or
        amending these Terms of Use shall only apply if confirmed in writing by MilkScout. By requesting registration in
        accordance with § 3, the User accepts these Terms of Use as binding.
      </Section>
      <Section number="1.3">
        MilkScout will notify the User of amendments to these Terms of Use in text form (e.g. by e-mail). If the User
        does not object to such amendments in text form (e.g. by e-mail) within 30 days of receipt of the notification,
        the amendments are considered to be agreed. The User will be separately informed of the right to object and the
        legal consequences of remaining silent in the case of an amendment to the Terms of Use. If the User does not
        agree with the changes, he/she has the right to terminate the contract without notice until the proposed
        effective date of the changes. The amended Terms of Use will additionally be published on the MilkScout website.
      </Section>
      <Paragraph number={2}>Service specification</Paragraph>
      <Section number="2.1">
        The Marketplace is a platform with the purpose of entering into sales contracts for milk, dairy raw materials
        and other dairy products (in particular skimmed milk, skimmed milk concentrates, cream, whey, buttermilk, liquid
        lactose; hereinafter: "Products") (hereinafter: "Sales Contracts") as well as freight forwarding contracts for
        the shipment of Products (hereinafter: "Freight Forwarding Contracts"; Sales Contracts and Freight Forwarding
        Contracts hereinafter jointly: "Transactions").
      </Section>
      <Section number="2.2">
        MilkScout itself does not offer any Products or forwarding services and does not become a party of the
        Transactions that are concluded exclusively between the Users of this Marketplace.
      </Section>
      <Section number="2.3">
        The Marketplace has an integrated, automated messaging system to support communication between Users, as well as
        extensive functionality to manage and monitor all ongoing Transactions.{' '}
      </Section>
      <Section number="2.4">
        <div>MilkScout provides the following services in accordance with the provisions set out below:</div>
        <Section number="2.4.1">
          Providing the access to and use of the Marketplace in accordance with these Terms of Use;
        </Section>
        <Section number="2.4.2">
          Enabling negotiations and closing of contracts in the Marketplace through User-initiated tenders and auctions;
        </Section>
        <Section number="2.4.3">
          Creating information and communication channels among the Users, particularly for the final coordination of
          prices depending on the volumes to be delivered and the specifications of the products after the fulfilment of
          the Sales Contracts (so-called MilkMatch).
        </Section>
      </Section>
      <Section number="2.5">
        The right of Users to use the Marketplace is limited by the current state of technology. MilkScout temporarily
        restricts its services if this is necessary with regard to capacity limits, the security or integrity of the
        servers or to carry out technical measures, if any of the above serves the proper or improved provision of the
        services (maintenance work). In these cases, MilkScout will take into account the legitimate interests of the
        Users and will inform them in advance if possible.
      </Section>
      <Section number="2.6">
        MilkScout reserves the right to change or expand the content and structure of the Marketplace and the User
        interfaces, if the fulfilment of the purpose of the agreement between the User and MilkScout is not or not
        significantly impaired as a result. MilkScout will inform the Users of the Marketplace about the changes
        accordingly.
      </Section>
      <Paragraph number={3}>Registration for the Marketplace; User Agreement</Paragraph>
      <Section number="3.1">
        Using the Marketplace is only permitted if the User is registered. The Marketplace is only open to entrepreneurs
        within the meaning of § 14 of the German Civil Code and legal entities of public law. There is no legal
        entitlement to registration or use of the Marketplace.{' '}
      </Section>
      <Section number="3.2">
        The User is obliged to provide the information specified in the registration mask (including his e-mail address,
        password, company name, company location, address, phone number and tax identification number) and to indicate
        whether he/she wishes to use the Marketplace as a "dairy" for the purchase or sale of Products (hereinafter{' '}
        <b>"Trade User"</b>) or as a "freight forwarder" as a provider of freight forwarding services (hereinafter{' '}
        <b>"Freight Forwarder"</b>).
      </Section>
      <Section number="3.3">
        The application for registration is accepted with a confirmation by e-mail. By registering, a use agreement for
        an indefinite period of time is concluded between MilkScout and the respective User in accordance with these
        Terms of Use.
      </Section>
      <Section number="3.4">
        Trade Users can enter further data at any time in a designated "company settings" mask on the Marketplace (e.g.
        loading times or naming specific other Users with whom Sales or Forwarding Contracts can be concluded or whose
        offers are not to be displayed to the Trade User).
      </Section>
      <Section number="3.5">
        Freight Forwarders can enter further data at any time in a designated "company settings" mask on the Marketplace
        (e.g. certificates).{' '}
      </Section>
      <Section number="3.6">
        The User guarantees that the information provided by him/her, in particular as part of his/her application for
        registration, to MilkScout and other Users is correct. The User obliges to immediately notify MilkScout of any
        future changes to the information provided on the Marketplace.{' '}
      </Section>
      <Paragraph number={4}>Login</Paragraph>
      <Section number="4.1">
        Each User is allowed to act on the Marketplace with several employees and logins. The User will be held
        accountable for the declarations and actions of his/her employees.{' '}
      </Section>
      <Section number="4.2">
        All logins are individualised and may only be used by the respective authorised person. The login and password
        must be kept secret and protected from unauthorised access by third parties. The User is responsible for
        maintaining the confidentiality of employee logins and will instruct his/her employees accordingly. If misuse by
        a third party is suspected, the User will immediately inform MilkScout of this. As soon as MilkScout becomes
        aware of the unauthorised use, MilkScout will block the access of the unauthorised user. MilkScout reserves the
        right to change the login and password of a User or employee; in such a case MilkScout will immediately inform
        the User thereof.{' '}
      </Section>
      <Section number="4.3">
        MilkScout has the right to temporarily or permanently block the User's access as a whole or in parts if there
        are concrete indications that the User is violating these Terms of Use and/or applicable law and if MilkScout's
        legitimate interest in blocking access outweighs the User's interest in accessing the Marketplace; in such a
        case MilkScout will immediately inform the User thereof.{' '}
      </Section>
      <Paragraph number={5}>Data protection; data processing</Paragraph>
      <Section number="5.1 ">
        By registering in accordance with § 3, the User warrants to MilkScout and all other Users that the requirements
        of data protection regulations will be complied with by the User in respect of the data transferred by him/her
        and indemnifies MilkScout against any claims, including claims under public law. In particular, the User must
        inform the persons acting on his behalf on the Marketplace (in particular employees) of the relevant data
        processing procedures required by MilkScout in accordance with MilkScout's data protection declaration at
        {joinUrl('milkscout.eu', DATA_PRIVACY_ROUTE)} and obtain any necessary consent from these persons before their
        personal data is submitted to the Marketplace as part of setting up employee logins or in any other way.{' '}
      </Section>
      <Section number="5.2 ">
        MilkScout's servers are secured in accordance with the current state of technology, in particular by firewalls;
        the User is aware, however, that there is a risk for all participants that transmitted data can be read out or
        deleted during transmission. This applies not only to the exchange of information via e-mail that leaves the
        system, but also to the integrated messaging system and all other transmissions of data. The confidentiality and
        availability of the data transmitted in the course of using the Marketplace can therefore not be guaranteed.{' '}
      </Section>
      <Section number="5.3 ">
        The user agrees that MilkScout may store information and non-personal data about the course of Transactions as
        well as the conduct of Users in carrying out Transactions in anonymised form and use it exclusively in this
        anonymised form for marketing purposes, e.g. for compiling statistics and presentations.{' '}
      </Section>
      <Section number="5.4 ">
        <div>
          MilkScout has the right to process and store the non-personal data received from the User in the course of the
          business relationship during the duration of this agreement. In particular, the User agrees that MilkScout:
        </div>
        <Section number="5.4.1">
          stores and processes the information provided by the User in the context of the registration application
          regarding company data and billing data as well as corresponding updates communicated by the User;
        </Section>
        <Section number="5.4.2">
          stores the data entered into the Marketplace by the User in the context of the registration pursuant to § 3
          and, in accordance with the provisions of § 6 and § 7 of these Terms of Use, makes such data accessible in the
          public section of the Marketplace for the other Users as specified by the User;
        </Section>
        <Section number="5.4.3">
          stores non-personal data on the content of the Transactions and makes it accessible to the respective Users in
          the public section of the Marketplace.
        </Section>
      </Section>
      <Section number="5.5 ">
        MilkScout will otherwise only use data relating to the User in accordance with these Terms of Use. MilkScout
        reserves the right to deviate from this if MilkScout has to disclose User data due to legal or administrative
        orders.
      </Section>
      <Section number="5.6 ">
        With regard to the processing of personal data, the User is referred to MilkScout's separate data protection
        declaration at {joinUrl('milkscout.eu', DATA_PRIVACY_ROUTE)}.
      </Section>
      <Section number="5.7 ">
        Users may not use addresses, e-mail addresses and other contact data obtained through the use of the Marketplace
        for any purpose other than for contractual and pre-contractual communication. In particular, it is prohibited to
        resell this data or to use it for sending advertising unless the respective User has expressly consented to this
        in advance in accordance with the applicable statutory provisions or has not objected to the permissible use of
        the contact data for advertising purposes without consent.
      </Section>
      <Section number="5.8 ">
        After termination of the use agreement, the data provided by the respective User or generated by his/her use of
        the Marketplace will be deleted. As an exception to this, MilkScout retains this information even after the end
        of the agreement, insofar as MilkScout is legally obliged to do so or has a legitimate interest in doing so.
        Aggregated and anonymised data generated through use (e.g. statistics on Transactions) will generally be
        retained even after the end of the contract.{' '}
      </Section>
      <Section number="5.9 ">
        MilkScout is only liable for the loss of data if such a loss could not have been avoided by appropriate data
        backup measures on the part of the User. The User is obligated to ensure an appropriate backup of all data
        provided or obtained by him/her in the context of the use of the Marketplace.{' '}
      </Section>
      <Section number="5.10">
        MilkScout has the right to use the e-mail address provided by the User in the context of the use of the
        Marketplace to send information about its own similar offers of goods and services (§ 7 para. 3 no. 4 of the
        German Act against Unfair Competition). The User may object to this use of his/her email address for the purpose
        of advertising to existing customers at any time by sending an email to info@milkscout.eu.
      </Section>
      <Paragraph number={6}>Conclusion of Sales Contracts on the Marketplace</Paragraph>
      <Section number="6.1">
        Trade Users who intend to sell Products (hereinafter <b>"Sellers"</b>) have the possibility to open tenders and
        to invite other (selected, if applicable, according to individual criteria pursuant to Clause 3.4) Trade Users
        who wish to buy Products (hereinafter <b>"Buyers"</b>) to do so.
      </Section>
      <Section number="6.2">
        When opening a tender, Sellers are required to specify the Product, the quantity, the price (as a fixed price or
        as a flexible price, that reduces itself every hour), the Product characteristics, the pick-up location and the
        pick-up day (hereinafter <b>"Sales Request"</b>). A description of the Products shall be made with regard to the
        legal legal definitions and requirements for production, labelling and packaging, in particular in accordance
        with Regulation (EU) 1308/2013 and the MilcherzeugnisVO (German regulation on milk products); for each Product,
        the Seller may choose between the standard specification provided by MilkScout and his/her own specification.
        The Sales Request is disclosed anonymously - i.e. without stating the Seller's company and contact details - to
        the Trade Users (who may be selected, if applicable, in accordance with Section 3.4). Unless the Seller cancels
        or amends the Sales Request, the Seller shall be bound by the information provided therein until the end of the
        pick-up day specified therein, however, the Sales Request itself is not a legally binding offer.{' '}
      </Section>
      <Section number="6.3">
        Buyers can submit binding offers in response to the Sales Request, to which they are bound for eight hours
        during a period from 8:00 a.m. to 5:00 p.m., Monday to Friday, from the time of submission (examples: (i) if a
        Buyer submits an offer at 4:00 p.m. on Thursday, he/she is bound by it until 3:00 p.m. on Friday; (ii) if he/she
        submits the offer at 8:30 a.m., he/she is bound by it until 4:30 p.m. on the same day). With the submission of
        an offer, the full details of the Seller and the contact details are disclosed to the Buyer and the Sales
        Request is no longer displayed on the Marketplace.{' '}
      </Section>
      <Section number="6.4">
        The Seller is free to choose whether to accept the offer. A contract is concluded when the Seller accepts the
        Buyer's offer. After the conclusion of the contract, the Buyer and the Seller receive a confirmation e-mail
        containing the essential data of the purchase contract.{' '}
      </Section>
      <Paragraph number={7}>Conclusion of Freight Forwarding Contracts on the Marketplace</Paragraph>
      <Section number="7.1">
        Buyers wishing to use freight forwarding services (hereinafter "Senders") have the option of opening auctions in
        which (selected, according to individual criteria pursuant to section 3.4) Freight Forwarders can submit bids.
      </Section>
      <Section number="7.2">
        When opening an auction, Senders are required to indicate the Product, the quantity, the maximum price, the
        pick-up location and the delivery location as well as any further information on the dispatch (hereinafter
        "Forwarding Offer"). The Forwarding Offer is disclosed to the Freight Forwarders (who may be selected, if
        applicable and in accordance with section 3.4). The Forwarding Offer is binding and the Sender is bound by the
        information provided therein for the auction period. The auction period is three hours after a Forwarding Offer
        is submitted on the Marketplace during a period from 8:00 a.m. to 5:00 p.m. (examples: (i) if a Forwarding Offer
        is posted at 4:00 p.m. on Thursday, the auction period will end at 10:00 a.m. on Friday; (ii) if a Forwarding
        Offer is posted at 9:30 a.m., the auction period will end at 12:30 p.m. on the same day).{' '}
      </Section>
      <Section number="7.3">
        Freight Forwarders can submit binding bids on the Forwarding Offer, which must be below the maximum price
        specified by the Sender. With the bid, the Freight Forwarder accepts the Sender's Forwarding Offer subject to
        the condition precedent that the Freight Forwarder is the lowest bidder at the end of the auction period.
        Freight Forwarders are bound by their bids until the end of the auction period described in section 7.2. The
        Freight Forwarding Contract is concluded with the Freight Forwarder who has offered the lowest price at the end
        of the auction period. After the conclusion of the contract, the Sender and the Freight Forwarder receive a
        confirmation e-mail containing the essential key data of the forwarding contract.
      </Section>
      <Paragraph number={8}>Execution of Sales and Forwarding Contracts concluded on the Marketplace</Paragraph>
      <Section number="8.1">
        The execution of Sales and Forwarding Contracts concluded on the Marketplace is the sole responsibility of the
        respective Users. MilkScout shall neither guarantee the fulfilment of the contracts concluded on the
        Marketplaces between the Users nor assume any liability for material defects or defects of title of the traded
        goods and services or any other performance failures. MilkScout is under no obligation to ensure the fulfilment
        of the contracts concluded between the Users.
      </Section>
      <Section number="8.2">
        MilkScout does not guarantee the true identity and the power of disposition of the Users. In case of doubt, both
        contractual partners are required to inform themselves in an appropriate form about the true identity and the
        power of disposition of the other contractual partner.{' '}
      </Section>
      <Paragraph number={9}>Transaction fees for concluded Sales and Forwarding Contracts</Paragraph>
      <Section number="9.1">
        MilkScout charges the respective Buyer a fee for the use of the Marketplace and for arranging the conclusion of
        a Sales Contract.{' '}
      </Section>
      <Section number="9.2">
        MilkScout charges the respective Freight Forwarder a fee for the use of the Marketplace and for arranging the
        conclusion of a Freight Forwarding Contract.
      </Section>
      <Section number="9.3">
        The individual fee rates are based on the current price list, which is available at milkscout.eu. MilkScout may
        change the fee rates at any time with effect for the future. Users will be notified of any changes to the fee
        rates in good time before they come into force.{' '}
      </Section>
      <Section number="9.4">
        MilkScout invoices the incurred fees to the Users who are obligated to pay on a monthly basis. MilkScout informs
        the User by e-mail about the provision of the respective invoice. The User can access the complete invoice via
        his login. All fees payable by the User to MilkScout shall be paid against proper invoicing plus the statutory
        value added tax.
      </Section>
      <Section number="9.5">
        Users under obligation to pay shall be in default without further notice after a period of 30 days after
        notification of the invoice amount.{' '}
      </Section>
      <Section number="9.6">
        The User is only entitled to set-off against MilkScout with counterclaims that are undisputed or have been
        legally determined by a court and are non-appealable.{' '}
      </Section>
      <Paragraph number={10}>Use of the Marketplace, contents</Paragraph>
      <Section number="10.1">
        Users are prohibited from placing content (e.g. through links or frames) on the Marketplace that violates
        statutory regulations, administrative orders or public morality. Furthermore, they are prohibited from posting
        content that infringes the rights, in particular copyrights, design rights or trademark rights of third parties.
      </Section>
      <Section number="10.2">
        The User guarantees MilkScout and the other Users that the goods and services offered by him/her in tenders or
        auctions, in particular Product images, descriptions or labels, do not infringe any copyrights, trademarks,
        designs, patents, other industrial property rights, business or trade secrets.{' '}
      </Section>
      <Section number="10.3">
        MilkScout does not under any circumstances assume ownership or responsibility for third-party content. User
        content published on the Marketplace does not represent the opinion of MilkScout and is generally not checked by
        MilkScout for its legality, correctness and completeness.
      </Section>
      <Section number="10.4">
        MilkScout reserves the right to block Users, tenders, auctions, requests, offers or other contents or to remove
        them from the Marketplace if they violate these Terms of Use, statutory regulations, administrative orders or
        public morality, or if there is reasonable cause for concern that the rights of third parties could be
        infringed.{' '}
      </Section>
      <Section number="10.5">
        The User will indemnify MilkScout against all claims asserted by third parties against MilkScout due to the
        infringement of their rights or due to legal violations based on the data and other content posted by the User.
        In this respect, the User will also bear the costs of MilkScout's legal defence, including all court costs and
        lawyers' fees.{' '}
      </Section>
      <Paragraph number={11}>Other obligations of the User</Paragraph>
      <Section number="11.1">
        <div>The User is obligated,</div>
        <Section number="11.1.1">
          to set up and maintain the necessary data security precautions throughout the term of the contract and to
          handle the logins and passwords carefully and diligently;{' '}
        </Section>
        <Section number="11.1.2">
          to immediately notify MilkScout of any technical changes or personnel changes occurring in his/her field of
          responsibility with regard to the employees authorised to access the Marketplace if they are likely to impair
          the provision of services or the security of the Marketplace;{' '}
        </Section>
        <Section number="11.1.3">
          to cooperate in the investigation of attacks by third parties against the Marketplace, insofar as this
          cooperation by the User is required;
        </Section>
        <Section number="11.1.4">
          to conduct business on the Marketplace exclusively within the scope of business operations for commercial
          purposes.{' '}
        </Section>
      </Section>
      <Section number="11.2">
        Users may not block, overwrite or modify content generated by MilkScout or other Users or otherwise interfere
        with the Marketplace in a disruptive manner, particularly if doing so could cause an excessive burden on the
        Marketplace infrastructure.{' '}
      </Section>
      <Section number="11.3">
        The User agrees to refrain from all actions which endanger or disrupt the functioning of the Marketplace and not
        to access data which he/she is not authorised to access. Furthermore, the User must ensure that the information
        and data transmitted via the Marketplace are not infected with viruses, worms or trojans. The User agrees to
        compensate MilkScout for all damages resulting from the User's failure to comply with these obligations and, in
        addition, to indemnify MilkScout against all claims of third parties, including lawyers' fees and court costs,
        which such third parties assert against MilkScout due to the User's failure to comply with these obligations.
      </Section>
      <Paragraph number={12}>Liability of MilkScout</Paragraph>
      <Section number="12.1">
        MilkScout is liable in accordance with the statutory regulations for deliberate intent and gross negligence on
        the part of MilkScout, its legal representatives, executive employees or other agents. The same applies in case
        of guarantees or other no-fault liability as well as in case of claims under the Produkthaftungsgesetz (German
        Product Liability Act) or culpable injury to life, limb or health.{' '}
      </Section>
      <Section number="12.2">
        MilkScout is liable for breaches of essential contractual obligations caused by simple negligence on the part of
        MilkScout, its representatives, executives and agents, i.e. obligations on whose fulfilment for the proper
        performance of the contract the User regularly relies and may rely on, in this case, however, limited to the
        amount of the typically arising, foreseeable damage.
      </Section>
      <Section number="12.3">Any further liability on the part of MilkScout is excluded.</Section>
      <Section number="12.4">
        Insofar as MilkScout's liability is excluded or limited, this also applies in favour of the personal liability
        of its legal representatives, executive employees and agents.{' '}
      </Section>
      <Section number="12.5">
        The liability does not extend to impairments of the contractual use of the services provided by MilkScout on the
        Marketplace caused by improper or incorrect use by the User.{' '}
      </Section>
      <Paragraph number={13}>Term of the User Agreement; Termination</Paragraph>
      <Section number="13.1">
        The agreement underlying these Terms of Use is concluded for an indefinite period. It begins with the
        registration of the User by MilkScout in accordance with § 3.{' '}
      </Section>
      <Section number="13.2">
        <Accordion
          summary={
            <div>
              The contract can be terminated by MilkScout and the user in each case with a notice of one month to the
              end of the month. <i className={classes.colored}>(translated)</i>
            </div>
          }
          details={
            <div>
              <Divider>german</Divider>
              <div>
                Der Vertrag kann von MilkScout und dem Nutzer jeweils mit einer Frist von einem Monat zum Monatsende
                gekündigt werden.
              </div>
            </div>
          }
        />
      </Section>
      <Section number="13.3">
        <div>
          Each party has the right to terminate the contract for good cause without giving prior notice. A good cause
          for MilkScout is in particular:{' '}
        </div>
        <Section number="13.3.1">
          a User's breach of these Terms of Use which is not remedied even after a deadline has been set;
        </Section>
        <Section number="13.3.2">
          he default of the User with the payment obligation pursuant to § 9 by more than six weeks.
        </Section>
        <Section number="13.3.3">
          permanent interruptions to business as a result of force majeure beyond MilkScout's control, e.g. natural
          disasters, fire, breakdown of supply networks without MilkScout's fault.{' '}
        </Section>
      </Section>
      <Section number="13.4">
        A termination has no effect on the validity of any contracts already concluded on the Marketplace and the
        resulting payment claims of MilkScout.{' '}
      </Section>
      <Paragraph number={14}>Final provisions</Paragraph>
      <Section number="14.1">
        A partial or complete transfer of the User's rights under the agreement with MilkScout to third parties is
        excluded.{' '}
      </Section>
      <Section number="14.2">
        All declarations made within the framework of the User agreement concluded with MilkScout must be made in
        writing or text form (e.g. by e-mail).{' '}
      </Section>
      <Section number="14.3">
        The law of the Federal Republic of Germany applies under exclusion of the UN Sales Law.{' '}
      </Section>
      <Section number="14.4">
        The place of jurisdiction for all legal disputes is Landshut, Germany. MilkScout is also entitled to take legal
        action at the User's place of jurisdiction.{' '}
      </Section>
      <Section number="14.5">
        Should any provision of these Terms of Use be or become void or ineffective in whole or in part, this will not
        affect the validity of the remaining provisions. Statutory law will replace any provisions of these Terms of Use
        that are not deemed to be applicable or are invalid. If such statutory law is not available in the respective
        case (regulatory gap) or would lead to an unacceptable result, MilkScout and the User will enter into
        negotiations to replace the non-applicable or invalid provision with a valid provision that comes as close as
        possible to it in economic terms.{' '}
      </Section>
    </>
  );
};
