import React, { PropsWithChildren } from 'react';
import { Button, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { joinClasses, openUrlInNewTab } from '@milkscout/react';
import { Video } from '../../../../components/Video';
import milkBackground from '../../../../assets/img/milk-background.png';
import { getNsTrans } from '../../../../translation';
import { InViewAnimation } from '../../../../components/animation/InViewAnimation';
import { BounceAnimation } from '../../../../components/animation/BounceAnimation';

const Animation = ({ children }: PropsWithChildren<unknown>) => (
  <InViewAnimation repeat animation={<BounceAnimation duration={1.8} />}>
    {children}
  </InViewAnimation>
);

const getClasses = makeStyles({
  content: {
    textAlign: 'center',
    paddingTop: 190,
    paddingBottom: 310,
    paddingLeft: 20,
    paddingRight: 20,
  },
  header: {
    fontFamily: 'Open Sans',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: 42,
  },
  subHeader: {
    textShadow: '0px 0px 10px rgb(0 0 0 / 50%)',
    color: '#fff',
    fontSize: 20,
    fontWeight: 'bold',
    lineHeight: '32px',
    marginTop: 15,
  },
  demoButton: {
    color: '#fff',
    fontWeight: 'bolder',
    textTransform: 'uppercase',
    fontSize: 14,
    letterSpacing: '1px',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 30,
    lineHeight: 'unset',
    marginTop: 80,
    fontFamily: 'Open Sans',
  },
  mobileBackground: {
    backgroundImage: `url(${milkBackground})`,
    backgroundSize: 'cover',
    backgroundPositionX: '50%',
    backgroundPositionY: '50%',
    paddingTop: 50,
    paddingBottom: 80,
  },
});

const Trans = getNsTrans('main-page');
export interface BannerContentProps {
  className?: string;
}
export const BannerContent = ({ className }: BannerContentProps) => {
  const classes = getClasses();
  return (
    <div className={joinClasses(classes.content, className)}>
      <Typography color="primary" className={classes.header}>
        <Trans>Rapid and easy trading of raw materials.</Trans>
      </Typography>
      <div className={classes.subHeader}>
        <Trans>The online B2B trading platform for liquid dairy products</Trans>
      </div>
      <Animation>
        <Button
          variant="contained"
          color="secondary"
          classes={{ contained: classes.demoButton }}
          onClick={() => openUrlInNewTab(process.env.REACT_APP_MILKSCOUT_LOGIN_DEMO_URL as string)}
        >
          <Trans>Register now</Trans>
        </Button>
      </Animation>
    </div>
  );
};

export const Banner = () => {
  const classes = getClasses();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (isMobile) {
    return <BannerContent className={classes.mobileBackground} />;
  }
  return (
    <Video videoSrc={process.env.REACT_APP_MILKSCOUT_MILK_ASSET_LINK as string} imageBackground={milkBackground}>
      <BannerContent />
    </Video>
  );
};
