import React, { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const getClasses = makeStyles(({ palette }: Theme) => ({
  root: { padding: 30 },
  title: {
    marginTop: 20,
    textTransform: 'uppercase',
    fontFamily: 'Open Sans',
    fontSize: 18,
    fontWeight: 600,
    color: palette.primary.main,
  },
  description: {
    marginTop: 20,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '25.6px',
    textAlign: 'center',
  },
  icon: {
    width: 64,
    height: 64,
    fill: palette.primary.main,
  },
}));

export interface CardProps {
  icon: ReactElement;
  title: ReactNode;
}

export const Card = ({ icon, title, children }: PropsWithChildren<CardProps>) => {
  const classes = getClasses();
  return (
    <div className={classes.root}>
      <div style={{ textAlign: 'center' }}>
        {React.cloneElement(icon, {
          className: classes.icon,
        })}
        <div className={classes.title}>{title}</div>
      </div>
      <div className={classes.description}>{children}</div>
    </div>
  );
};
