import React, { useRef } from 'react';
import { Button, ClickAwayListener, Grow, makeStyles, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { useMountedState } from '@milkscout/react';
import { ChevronDownIcon } from '@material-ui-extra/icons/ChevronDownIcon';
import { useHistory } from 'react-router-dom';
import { NAVIGATION_ITEMS } from './NavigationItems';
import { getNsTrans } from '../../../../../translation';

const getClasses = makeStyles({
  menuItem: {
    fontSize: 14,
  },
});

const Trans = getNsTrans('menu');

export const NavigationMenu = () => {
  const history = useHistory();
  const [open, setOpen] = useMountedState(false);
  const anchorRef = useRef(null);
  const classes = getClasses();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    const { current } = anchorRef;
    // @ts-ignore
    if (current !== null && current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      // @ts-ignore
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <div
      style={{
        flex: 1,
        textAlign: 'center',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={<ChevronDownIcon />}
      >
        <Trans>About MilkScout</Trans>
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {NAVIGATION_ITEMS.map(({ label, route }) => (
                    <MenuItem
                      key={`menu-mobile-${route}`}
                      className={classes.menuItem}
                      onClick={(evt) => {
                        history.push(route);
                        handleClose(evt);
                      }}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
