import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { BuyerPriceCard, HaulerPriceCard, SellerPriceCard } from './PriceCard';

export interface PricingProps {
  className?: string;
}

export const Pricing = ({ className }: PricingProps) => (
  <Container maxWidth="lg" className={className}>
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={6} md={4}>
        <SellerPriceCard />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <BuyerPriceCard />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <HaulerPriceCard />
      </Grid>
    </Grid>
  </Container>
);
