import React, { useEffect, useState } from 'react';
import { Trans, TransProps } from 'react-i18next';
import { LANGUAGE_CHANGED_EVENT_NAME } from '../variables';

export const getNsTrans =
  (ns: string) =>
  ({ children, ...props }: Omit<TransProps, 'ns'>) => {
    const [, setState] = useState(true);
    useEffect(() => {
      const eventHandler = () => setState((c) => !c);
      window.addEventListener(LANGUAGE_CHANGED_EVENT_NAME, eventHandler);
      return () => window.removeEventListener(LANGUAGE_CHANGED_EVENT_NAME, eventHandler);
    });
    return (
      <Trans ns={ns} {...props}>
        {children}
      </Trans>
    );
  };
