import 'custom-event-polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './service-worker';
import { CIThemeProvider } from './corporate-identity/Theme';
import { initSentry } from './defaults/sentry';
import * as infoWorker from './version-worker';
import { App } from './App';

initSentry();

ReactDOM.render(
  <CIThemeProvider>
    <App />
  </CIThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
infoWorker.register(parseInt(process.env.REACT_APP_UPDATE_INTERVAL_IN_SECONDS || '90', 10));
