import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const getClasses = makeStyles({
  root: {
    marginTop: 25,
    display: 'flex',
    flexDirection: 'row',
  },
  text: { flex: 1, paddingLeft: 12 },
});

export interface ParagraphProps {
  number: number;
  children: ReactNode;
}

export const Paragraph = ({ number, children }: ParagraphProps) => {
  const classes = getClasses();
  return (
    <h2 className={classes.root}>
      <div>{`§ ${number} `}</div>
      <div className={classes.text}>{children}</div>
    </h2>
  );
};
