import 'custom-event-polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { Router } from 'react-router-dom';
import { ErrorTrans } from '@milkscout/react/component/ErrorTrans';
import { createBrowserHistory } from 'history';
import { Routes } from 'Routes';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Helmet } from './defaults/Helmet';
import { I18nextProvider } from './translation';
import { PickerProvider } from './defaults/PickerProvider';

const ROUTER_HISTORY = createBrowserHistory();

export const App = () => (
  <Router history={ROUTER_HISTORY}>
    <ParallaxProvider>
      <I18nextProvider>
        <ErrorTrans />
        <Helmet />
        <PickerProvider>
          <Routes />
        </PickerProvider>
      </I18nextProvider>
    </ParallaxProvider>
  </Router>
);
