import React, { PropsWithChildren, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';
import { joinClasses } from '@milkscout/react';

const getClasses = makeStyles({
  root: { textAlign: 'center', padding: 30 },
  rootClick: { cursor: 'pointer' },
  icon: {
    width: 60,
    height: 60,
    fill: '#fff',
  },
  divider: {
    borderBottom: '2px solid #fff',
    width: '50%',
    margin: 'auto',
  },
  dividerWrapper: {
    marginTop: 30,
    marginBottom: 30,
  },
  children: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 600,
  },
});
export interface FooterCardProps {
  icon: ReactElement;
  onClick?: () => void;
}

export const FooterCard = ({ icon, children, onClick }: PropsWithChildren<FooterCardProps>) => {
  const classes = getClasses();
  return (
    <div className={joinClasses({ [classes.root]: true, [classes.rootClick]: !!onClick })} onClick={onClick}>
      <div>
        {React.cloneElement(icon, {
          className: classes.icon,
        })}
      </div>
      <div className={classes.dividerWrapper}>
        <div className={classes.divider} />
      </div>
      <div className={classes.children}>{children}</div>
    </div>
  );
};
