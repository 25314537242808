import React, { useCallback, useEffect, useRef } from 'react';
import { Button, ClickAwayListener, Grow, makeStyles, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { useMountedState } from '@milkscout/react';
import { ChevronDownIcon } from '@material-ui-extra/icons/ChevronDownIcon';
import { useTranslation } from 'react-i18next';
import { LanguageItem } from './LanguageItem';
import { Language, LanguageValues, parseLanguage } from '../../../../../translation';

const getClasses = makeStyles({
  root: {
    flex: 1,
    textAlign: 'center',
    display: 'flex',
  },
  language: {
    display: 'flex',
    cursor: 'pointer',
    padding: '6px 8px',
  },
  languageText: {
    alignSelf: 'center',
  },
  flag: {
    height: 25,
    marginRight: 5,
  },
});

export const LanguageMenu = () => {
  const { i18n } = useTranslation();
  const language = parseLanguage(i18n.language);
  const [open, setOpen] = useMountedState(false);

  const anchorRef = useRef(null);
  const classes = getClasses();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLanguageChange = useCallback(
    async (lng) => {
      await i18n.changeLanguage(lng);
      setOpen(false);
    },
    [i18n, setOpen],
  );

  const handleClose = (event: any) => {
    const { current } = anchorRef;
    // @ts-ignore
    if (current !== null && current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      // @ts-ignore
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div style={{ flex: 1, textAlign: 'center', display: 'block' }}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={<ChevronDownIcon />}
      >
        <LanguageItem language={language as Language} />
      </Button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {LanguageValues.map((lng) => (
                    <MenuItem
                      key={`select-${lng}`}
                      className={classes.language}
                      onClick={() => handleLanguageChange(lng)}
                    >
                      <LanguageItem language={lng} active={language !== lng} />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
