import React from 'react';
import { makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { joinClasses, when } from '@milkscout/react';
import { Video } from '../../../components/Video';
import haulerBackground from '../../../assets/img/hauler-background.png';
import { getNsTrans } from '../../../translation';

const getClasses = makeStyles({
  content: {
    textAlign: 'center',
  },
  header: {
    color: '#fff',
    fontWeight: 600,
    fontSize: 54,
  },
  mobileBackground: {
    backgroundImage: `url(${haulerBackground})`,
    backgroundSize: 'cover',
    backgroundPositionX: '50%',
    backgroundPositionY: '50%',
  },
  shadow: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
});

const Trans = getNsTrans('hauler-page');

export interface BannerContentProps {
  className?: string;
  isMobile?: boolean;
}

export const BannerContent = ({ className, isMobile = false }: BannerContentProps) => {
  const classes = getClasses();
  return (
    <div className={joinClasses(classes.content, className)}>
      <div className={classes.shadow}>
        <div
          style={when(
            isMobile,
            {
              paddingTop: 180,
              paddingBottom: 180,
              paddingLeft: 20,
              paddingRight: 20,
            },
            {
              paddingTop: 280,
              paddingBottom: 280,
              paddingLeft: 20,
              paddingRight: 20,
            },
          )}
        >
          <Typography color="primary" className={classes.header}>
            <Trans>MilkScout for hauler.</Trans>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const Banner = () => {
  const classes = getClasses();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  if (isMobile) {
    return <BannerContent isMobile className={classes.mobileBackground} />;
  }
  return (
    <Video videoSrc={process.env.REACT_APP_MILKSCOUT_HAULER_ASSET_LINK as string} imageBackground={haulerBackground}>
      <BannerContent />
    </Video>
  );
};
