import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { formatNumber, FormattedNumber, parseFloat } from '@milkscout/react';
import { Theme } from '@material-ui/core/styles';
import { ListItem } from './ListItem';
import { getNsTrans } from '../../../translation';

const getClasses = makeStyles(({ palette }: Theme) => ({
  root: {
    margin: 20,
    padding: 40,
    borderRadius: 15,
    boxShadow: 'rgba(2, 110, 159, 0.2) 0px 10px 20px 2px',
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    marginBottom: 20,
  },
  price: {
    fontSize: 35,
    fontFamily: 'Open Sans',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 20,
  },
  children: {
    color: palette.primary.main,
    fill: palette.primary.main,
  },
}));

interface PriceCardProps {
  title: ReactNode;
  price: ReactNode;
  style?: CSSProperties;
}
const Trans = getNsTrans('price-card');
const PriceCard = ({ title, price, children, style }: PropsWithChildren<PriceCardProps>) => {
  const classes = getClasses();
  return (
    <Box boxShadow={3} className={classes.root} style={style}>
      <div className={classes.title}>{title}</div>
      <div className={classes.price}>{price}</div>
      <div className={classes.children}>{children}</div>
    </Box>
  );
};

export const SellerPriceCard = () => {
  const { i18n } = useTranslation();
  return (
    <PriceCard
      title={<Trans>Seller</Trans>}
      price={
        <span>
          <FormattedNumber
            number={parseFloat(process.env.REACT_APP_MILKSCOUT_SELLER_PRICE as string)!}
            locale={i18n.language}
          />
          <span> </span>
          <span>{process.env.REACT_APP_MILKSCOUT_UNIT as string}</span>
        </span>
      }
    >
      <ListItem>
        <Trans>Free quotation</Trans>
      </ListItem>
      <ListItem>
        <Trans>No fees for successful sale</Trans>
      </ListItem>
      <ListItem>
        <Trans>Free cancellation as long as the offer is not yet sold</Trans>
      </ListItem>
    </PriceCard>
  );
};

export const BuyerPriceCard = () => {
  const { i18n } = useTranslation();
  return (
    <PriceCard
      title={<Trans>Buyer</Trans>}
      price={
        <span>
          <FormattedNumber
            number={parseFloat(process.env.REACT_APP_MILKSCOUT_BUYER_PRICE as string)!}
            locale={i18n.language}
          />
          <span> </span>
          <span>{process.env.REACT_APP_MILKSCOUT_UNIT as string}</span>
        </span>
      }
    >
      <ListItem>
        <Trans
          tOptions={{
            price: formatNumber(i18n.language, parseFloat(process.env.REACT_APP_MILKSCOUT_BUYER_PRICE as string)!),
            unit: process.env.REACT_APP_MILKSCOUT_UNIT as string,
          }}
        >
          {'{{price}} {{unit}} per successfully brokered transaction.'}
        </Trans>
      </ListItem>
      <ListItem>
        <Trans>Invoice at the end of the month</Trans>
      </ListItem>
      <ListItem>
        <Trans>Free creation of transport offers</Trans>
      </ListItem>
    </PriceCard>
  );
};

export const HaulerPriceCard = () => {
  const { i18n } = useTranslation();
  return (
    <PriceCard
      title={<Trans>Hauler</Trans>}
      price={
        <span>
          <FormattedNumber
            number={parseFloat(process.env.REACT_APP_MILKSCOUT_HAULER_UNDER_150_KM as string)!}
            locale={i18n.language}
          />
          <span> </span>
          <span>{process.env.REACT_APP_MILKSCOUT_UNIT as string}</span>
        </span>
      }
    >
      <ListItem>
        <Trans
          tOptions={{
            price: formatNumber(
              i18n.language,
              parseFloat(process.env.REACT_APP_MILKSCOUT_HAULER_UNDER_150_KM as string)!,
            ),
            unit: process.env.REACT_APP_MILKSCOUT_UNIT as string,
          }}
        >
          {'{{price}} {{unit}} per transport order for max. 150 km'}
        </Trans>
      </ListItem>
      <ListItem>
        <Trans
          tOptions={{
            price: formatNumber(
              i18n.language,
              parseFloat(process.env.REACT_APP_MILKSCOUT_HAULER_OVER_150_KM as string)!,
            ),
            unit: process.env.REACT_APP_MILKSCOUT_UNIT as string,
          }}
        >
          {'{{price}} {{unit}} per transport order for more than 150 km'}
        </Trans>
      </ListItem>
    </PriceCard>
  );
};
