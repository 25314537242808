import React from 'react';
import { Button, Grid } from '@material-ui/core';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { getNsTrans } from '../../../translation';

const getClasses = makeStyles(({ palette }: Theme) => ({
  container: {
    padding: 80,
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px -10px 30px 2px',
  },
  header: {
    color: palette.primary.main,
    fontSize: 36,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  content: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 20,
    marginTop: 20,
  },
  button: {
    color: '#ffffff',
    textTransform: 'uppercase',
    fontWeight: 700,
  },
}));
const Trans = getNsTrans('info-register');
export const Register = () => {
  const classes = getClasses();
  return (
    <Grid container>
      <Grid item md={6} xs={12} className={classes.container}>
        <div className={classes.header}>
          <Trans>Do you want to trade quickly and conveniently?</Trans>
        </div>
        <div className={classes.content}>
          <Trans>
            MilkScout is an independent platform that simplifies milk trading and helps you realize the full potential
            of the dairy market
          </Trans>
        </div>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={classes.button}
          href={process.env.REACT_APP_MILKSCOUT_REGISTER_URL as string}
          target="_blank"
        >
          <Trans>Register for free</Trans>
        </Button>
      </Grid>
    </Grid>
  );
};
