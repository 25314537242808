import React, { CSSProperties } from 'react';

export interface LightBulbProps {
  style?: CSSProperties;
  className?: string;
}

export const LightBulb = ({ style, className }: LightBulbProps) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    className={className}
    viewBox="0 0 1085.3815 1896.0833"
  >
    <path d="M736 576q0 13-9.5 22.5T704 608t-22.5-9.5T672 576q0-46-54-71t-106-25q-13 0-22.5-9.5T480 448t9.5-22.5T512 416q50 0 99.5 16t87 54 37.5 90zm160 0q0-72-34.5-134t-90-101.5-123-62T512 256t-136.5 22.5-123 62-90 101.5T128 576q0 101 68 180 10 11 30.5 33t30.5 33q128 153 141 298h228q13-145 141-298 10-11 30.5-33t30.5-33q68-79 68-180zm128 0q0 155-103 268-45 49-74.5 87t-59.5 95.5-34 107.5q47 28 47 82 0 37-25 64 25 27 25 64 0 52-45 81 13 23 13 47 0 46-31.5 71t-77.5 25q-20 44-60 70t-87 26-87-26-60-70q-46 0-77.5-25t-31.5-71q0-24 13-47-45-29-45-81 0-37 25-64-25-27-25-64 0-54 47-82-4-50-34-107.5T177.5 931 103 844Q0 731 0 576q0-99 44.5-184.5t117-142 164-89T512 128t186.5 32.5 164 89 117 142T1024 576z" />
  </svg>
);
