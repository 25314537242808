import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import { ListItemIcon } from '../../../components/images/ListItemIcon';

const getClasses = makeStyles({
  root: { display: 'flex', marginBottom: 15 },
  text: { marginLeft: 20, lineHeight: '26px', fontSize: 18 },
  icon: {
    height: 26,
  },
});
export const ListItem = ({ children }: PropsWithChildren<unknown>) => {
  const classes = getClasses();
  return (
    <div className={classes.root}>
      <div>
        <ListItemIcon className={classes.icon} />
      </div>
      <div className={classes.text}>{children}</div>
    </div>
  );
};
