import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const getClasses = makeStyles(({ palette }: Theme) => ({
  root: {
    backgroundColor: palette.primary.main,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 42,
    fontFamily: 'Open Sans',
    fontWeight: 300,
    padding: 35,
    color: '#fff',
  },
}));

export const Header = ({ children }: PropsWithChildren<unknown>) => {
  const classes = getClasses();
  return <div className={classes.root}>{children}</div>;
};
