import React, { PropsWithChildren, ReactElement } from 'react';
import { InView } from '../InView';

export interface InViewAnimationProps {
  animation: ReactElement;
  repeat?: boolean;
}

export const InViewAnimation = ({ children, repeat, animation }: PropsWithChildren<InViewAnimationProps>) => {
  const clonedElement = React.cloneElement(animation, {}, children);
  return (
    <InView defaultNode={children} repeat={repeat}>
      {clonedElement}
    </InView>
  );
};
