import CLEANING_ROECK from "./cleaning-roeck.jpg";
import TRANSPORT_AWS from "./transport-aws.jpg";
import TRANSPORT_BERKMANN from "./transport-berkmann.jpg";
import TRANSPORT_BIEL_TRANSPORTE from "./transport-biel-transporte.jpg";
import TRANSPORT_DOBELMANN from "./transport-dobelmann.jpg";
import TRANSPORT_DOKTOR_CARGO from "./transport-doktor-cargo.jpg";
import TRANSPORT_FUCHS_TRANSPORTE from "./transport-fuchs-transporte.jpg";
import TRANSPORT_GROISS from "./transport-groiss.jpg";
import TRANSPORT_JUNG from "./transport-jung.jpg";
import TRANSPORT_KRALOWETZ from "./transport-kralowetz.jpg";
import TRANSPORT_LETTEL from "./transport-lettel.jpg";
import TRANSPORT_LIQUIDMASTER from "./transport-liquidmaster.jpg";
import TRANSPORT_PIENDL from "./transport-piendl.jpg";
import TRANSPORT_ROECK from "./transport-roeck.jpg";
import TRANSPORT_SCHALBECK_KALB from "./transport-schalbeck-kalb.jpg";
import TRANSPORT_STREDELE from "./transport-stredele.jpg";

export type TransportType = {
    CLEANING_ROECK: string;
    TRANSPORT_AWS: string;
    TRANSPORT_BERKMANN: string;
    TRANSPORT_BIEL_TRANSPORTE: string;
    TRANSPORT_DOBELMANN: string;
    TRANSPORT_DOKTOR_CARGO: string;
    TRANSPORT_FUCHS_TRANSPORTE: string;
    TRANSPORT_GROISS: string;
    TRANSPORT_JUNG: string;
    TRANSPORT_KRALOWETZ: string;
    TRANSPORT_LETTEL: string;
    TRANSPORT_LIQUIDMASTER: string;
    TRANSPORT_PIENDL: string;
    TRANSPORT_ROECK: string;
    TRANSPORT_SCHALBECK_KALB: string;
    TRANSPORT_STREDELE: string,
}

export const TRANSPORT_PARTNERS: TransportType = {
    CLEANING_ROECK,
    TRANSPORT_AWS,
    TRANSPORT_BERKMANN,
    TRANSPORT_BIEL_TRANSPORTE,
    TRANSPORT_DOBELMANN,
    TRANSPORT_DOKTOR_CARGO,
    TRANSPORT_FUCHS_TRANSPORTE,
    TRANSPORT_GROISS,
    TRANSPORT_JUNG,
    TRANSPORT_KRALOWETZ,
    TRANSPORT_LETTEL,
    TRANSPORT_LIQUIDMASTER,
    TRANSPORT_PIENDL,
    TRANSPORT_ROECK,
    TRANSPORT_SCHALBECK_KALB,
    TRANSPORT_STREDELE,
}