import React from 'react';
import { PlusIcon } from '@material-ui-extra/icons/PlusIcon';
import { IconButton, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { MinusIcon } from '@material-ui-extra/icons/MinusIcon';

const getClasses = makeStyles(({ palette }: Theme) => ({
  root: {
    backgroundColor: palette.primary.main,
    borderRadius: 'unset',
    color: '#fff',
    '&:hover': {
      backgroundColor: palette.primary.dark,
      color: '#fff',
    },
  },
}));
export interface AccordionIconProps {
  expanded: boolean;
}
export const AccordionIcon = ({ expanded }: AccordionIconProps) => {
  const classes = getClasses();
  return (
    <IconButton className={classes.root}>
      {expanded && <MinusIcon />}
      {!expanded && <PlusIcon />}
    </IconButton>
  );
};
