import React, { CSSProperties } from 'react';

export interface EmailProps {
  style?: CSSProperties;
  className?: string;
}

export const Email = ({ style, className }: EmailProps) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" style={style} className={className} viewBox="0 0 512 545.5">
    <path d="M48 160h416v288H48V160zm69 32l139 92.5L395 192H117zm-37 14v210h352V206L265 317.5l-9 5.5-9-5.5z" />
  </svg>
);
