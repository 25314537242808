export const MAIN_ROUTE = '/';
export const PRESS_ROUTE = '/press';
export const ABOUT_US_ROUTE = '/about-us';
export const HOW_DOES_IT_WORK_ROUTE = '/how-does-it-work';
export const HAULER_ROUTE = '/hauler';
export const FAQ_ROUTE = '/faq';

export const TERMS_OF_USE_ROUTE = '/terms-of-use';
export const DATA_PRIVACY_ROUTE = '/data-privacy';
export const IMPRINT_ROUTE = '/imprint';
